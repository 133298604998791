import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './App';
import * as Sentry from '@sentry/react';
import ReactNotification from 'react-notifications-component';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { createStoreWithSageMiddleware } from './store/config/store.config';
import interceptors from './service/interceptors-service';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import 'react-chat-widget/lib/styles.css';
import 'antd/dist/antd.css';
import './App.scss';

if (process.env.REACT_APP_API_ENV === 'PRODUCTION') {
  Sentry.init({
    dsn:
      'https://4975f24348f747019ba4b386fead846e@o486755.ingest.sentry.io/5669563',
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// creamos nuestro STORE
const store = createStoreWithSageMiddleware();

interceptors.setupInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ReactNotification />
      <AppContainer />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
