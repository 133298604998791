import React, { Component } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import Modal from 'react-modal';
import { Button } from 'primereact/button';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Create new plugin instance
const defaultLayoutPluginInstance = defaultLayoutPlugin();
const ZoomPluginInstance = zoomPlugin();
const { ZoomInButton, ZoomOutButton, ZoomPopover } = ZoomPluginInstance;

class PDFViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      b64Pdf: props.base64,
      modalIsOpen: props.modalIsOpen,
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cerrarModal = this.cerrarModal.bind(this);
    this.resetB64 = props.resetB64;
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false, base64: null });
    this.resetB64();
  }
  cerrarModal() {
    this.setState({ modalIsOpen: false, base64: null });
    this.resetB64();
  }

  render() {
    const base64 = this.state.b64Pdf;
    //const blob = base64toBlob(base64);
    //const url = URL.createObjectURL(blob);
    let customStyles = {};
    if (window.innerWidth < 768) {
      customStyles = {
        content: {
          marginTop: '6vh',
          width: '100%',
          zIndex: '10000',
          inset: 'none',
          justifyContent: 'center',
          marginLeft: '-40px',
          padding: '30px 20px 20px 20px',
        },
      };
    } else {
      customStyles = {
        content: {
          top: '55%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          height: '90vh',
        },
      };
    }

    Modal.setAppElement('#root');
    return (
      /*<Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Modal PDF"               
            >
            {window.innerWidth < 768 &&  <span onClick={this.closeModal} style={{"float":"right","position":"absolute", "zIndex":"100","marginTop":"-24px"}}>CERRAR</span> }
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                    
                    
                    <Viewer
                        fileUrl={"data:application/pdf;base64,"+base64}
                        plugins={[

                            defaultLayoutPluginInstance,

                        ]}
                    />
                </Worker>

            </Modal>*/
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.state.afterOpenModal}
        onRequestClose={this.closeModal}
        style={customStyles}
        //   className="pdf-viewer-container"
        contentLabel="Modal PDF"
      >
        <div
          style={{
            marginLeft: '90%',
            marginBottom: '5px',
          }}
        >
          <Button
            onClick={this.closeModal}
            icon="pi pi-times"
            className="p-button-rounded close-button"
          />
        </div>
        <div style={{ width: '100%' }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: '#eeeeee',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px',
                }}
              >
                <ZoomOutButton />
                <ZoomPopover />
                <ZoomInButton />
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                <Viewer
                  fileUrl={'data:application/pdf;base64,' + base64}
                  plugins={[defaultLayoutPluginInstance, ZoomPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </div>
            </div>
          </Worker>
        </div>
      </Modal>
    );
  }
}

export default PDFViewer;
