import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Redirect } from "react-router";
import { InputText } from "primereact/inputtext";
import Moment from "react-moment";
import { nanoid } from "nanoid";

const DataTableExpedientes = ({
  onLoad,
  expedientes,
  history,
  putExpediente,
  fetching,
  showArchivados,
}) => {
  const [paramExpediente, setParamExpediente] = useState({
    Keyword: "",
    IsEnabled: true,
    SortingCriteria: "",
    MaxResultCount: 9,
    SkipCount: 0,
    Estados: ["Preparado", "EnEstudio"],
  });

  const [items, setItems] = useState([]);
  const [, setUpdatedExpediente] = useState([]);
  const [selectedExpediente, setSelectedExpediente] = useState();
  const [sortConfig, setSortConfig] = useState({});
  const [first, setFirst] = useState();
  const [referenciaInitValue, setReferenciaInitValue] = useState();
  const [originalRows, setOriginalRows] = useState([]);
  const [redirect] = useState(false);

  useEffect(() => {
    onLoad(paramExpediente);
  }, [onLoad, paramExpediente]);

  useEffect(() => {
    if (showArchivados) {
      setParamExpediente((value) => ({ ...value, Estados: ["Finalizado"] }));
    } else {
      setParamExpediente((value) => {
        if (value?.Estados?.includes("Finalizado")) {
          return { ...value, Estados: ["Preparado", "EnEstudio"] };
        }
        return value;
      });
    }
  }, [showArchivados]);

  useEffect(() => {
    setItems(expedientes.items);
  }, [expedientes]);

  //#region table elements

  const standardTextRender = (rowData, fieldData) => {
    return (
      <div className="row">
        <div className="col-5">
          <span className="p-column-title">{fieldData.header}</span>
        </div>
        <div className="col-7">
          {rowData[fieldData?.field] ? rowData[fieldData.field] : ""}
        </div>
      </div>
    );
  };

  const standardTextDemandados = (rowData, fieldData) => {
    return (
      <div className="demandados__row">
        {rowData?.demandados?.map((item) => (
          <p key={nanoid()}>{item}</p>
        ))}
      </div>
    );
  };

  const principalNombreBodyTemplate = (rowData, fieldData) => {
    let formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    });

    return (
      <div className="row">
        <div className="col-5">
          <span className="p-column-title">{fieldData?.header}</span>
        </div>
        <div className="col-7">{formatter.format(rowData?.principal)}</div>
      </div>
    );
  };
  const accionBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Cantidad Acciones</span>
        <div className={rowData?.cantidadAcciones ? "actions-format" : ""}>
          <span>
            {rowData?.cantidadAcciones
              ? rowData.cantidadAcciones + " acciones pendientes"
              : ""}
          </span>
        </div>
      </>
    );
  };

  const fechaBodyTemplate = (rowData, fieldData) => {
    return (
      <div className="row">
        <div className="col-5">
          <span className="p-column-title">{fieldData?.header}</span>
        </div>
        <div className="col-7">
          {rowData?.fechaSiguienteAccion ? (
            <Moment format="DD/MM/YYYY">{rowData.fechaSiguienteAccion}</Moment>
          ) : (
            "No hay fecha de acción"
          )}
        </div>
      </div>
    );
  };

  //#endregion
  //#region helpers
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //#endregion
  //#region table events
  const onRowSelect = (event) => {
    history.push({
      pathname: "/expediente-detalle/" + event.data.id,
      state: { detail: event.data },
    });
  };
  /**
   * Retorna en caso de que no haya valor en sort.
   * @param {*} event
   */
  const customSort = (event) => {
    let order = "";
    let field = capitalize(event.sortField);
    setSortConfig({
      sortOrder: event.sortOrder,
      sortField: event.sortField,
    });
    if (event.sortOrder === 1) {
      order = "ASC";
    } else if (event.sortOrder === -1) {
      order = "DESC";
    } else {
      return;
    }
    let criteria = field + " " + order;
    setParamExpediente({
      ...paramExpediente,
      SortingCriteria: criteria,
    });
    setFirst(event.first);
  };

  /**
   * Paginación en back.
   * @param {*} event
   */
  const onpageChange = (event) => {
    setParamExpediente({
      ...paramExpediente,
      SkipCount: event.first,
    });
    setFirst(event.first);
  };
  //#endregion
  //#region table row edit
  const onRowEditInit = (event) => {
    // console.log('evento', event)
    setReferenciaInitValue(event.data.referencia);
    const clonedRows = [...originalRows];
    clonedRows[event.index] = {
      ...items[event.index],
    };
    setOriginalRows(clonedRows);
  };

  const onRowEditCancel = (event) => {
    // console.log(event)
    let exp = [...items];
    const clonedRows = [...originalRows];
    exp[event.index] = clonedRows[event.index];
    delete clonedRows[event.index];
    setOriginalRows(clonedRows);
    setItems(exp);
  };

  const refEditor = (expKey, props) => {
    return inputTextEditor(expKey, props, "referencia");
  };
  const inputTextEditor = (expKey, props, field) => {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) => onEditorValueChange(expKey, props, e.target.value)}
      />
    );
  };
  const onEditorValueChange = (expKey, props, value) => {
    let updatedExpediente = [...props.value];
    updatedExpediente[props.rowIndex][props.field] = value;
    setUpdatedExpediente(updatedExpediente);
  };
  const onEditorSubmit = (event) => {
    if (event.data.referencia !== referenciaInitValue) {
      var nuevoExpedienteReferencia = {
        lastModificationTime: event.data.lastModificationTime,
        referencia: event.data.referencia,
        id: event.data.id,
      };
      putExpediente(nuevoExpedienteReferencia);
    }
  };
  //#endregion

  if (redirect) {
    return <Redirect push to="/expediente-detalle" />;
  }
  return (
    <div className="k-drawer">
      <div className="datatable-templating datatable-responsive">
        <h2 className="expedientes-title">
          Procedimientos {showArchivados ? "archivados" : ""}
        </h2>
        <DataTable
          className="p-datatable-responsive"
          value={items}
          lazy
          totalRecords={expedientes?.totalCount ? expedientes?.totalCount : 0}
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={9}
          first={first}
          currentPageReportTemplate="Hay un total de {totalRecords} expedientes"
          onPage={onpageChange}
          onSort={customSort}
          sortField={sortConfig.sortField}
          sortOrder={sortConfig.sortOrder}
          bodyStyle
          loading={fetching}
          removableSort
          selection={selectedExpediente}
          onSelectionChange={(e) => setSelectedExpediente(e.value)}
          onRowSelect={onRowSelect}
          selectionMode="single"
          dataKey="id"
          editMode="row"
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          onRowEditSave={onEditorSubmit}
        >
          <Column
            rowEditor
            headerStyle={{ width: "7rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            field="referencia"
            header="Referencia"
            sortable
            body={standardTextRender}
            editor={(props) => refEditor("expediente", props)}
          ></Column>
          <Column
            field="procedimiento"
            header="Procedimiento"
            sortable
            body={standardTextRender}
          ></Column>
          <Column
            field="juzgado"
            header="Juzgado"
            sortable
            body={standardTextRender}
          ></Column>
          <Column
            field="demandados"
            header="Demandados"
            sortable
            body={standardTextDemandados}
          ></Column>
          <Column
            field="tipoExpedienteNombre"
            header="Tipo Expediente"
            body={standardTextRender}
          ></Column>
          <Column
            field="cantidadAcciones"
            header="Acción"
            body={accionBodyTemplate}
          ></Column>
          <Column
            field="fechaSiguienteAccion"
            header="Fecha de acción"
            sortable
            body={fechaBodyTemplate}
          ></Column>
          <Column
            field="principal"
            header="Principal"
            sortable
            style={{ textAlign: "right" }}
            body={principalNombreBodyTemplate}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default DataTableExpedientes;
