import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

const RowExpansionTemplate = (data) => {
  return (
    <div>
      <Accordion key={data.data.id}>
        {data.data.valorPropiedades.map((tipo) => {
          return (
            <AccordionTab key={tipo.id} header={tipo.propiedad.nombre}>
              {tipo.propiedad.tipo === 'System.DateTime' && tipo.valor}
              {tipo.propiedad.tipo === 'System.String' && tipo.valor}
              {tipo.propiedad.tipo === 'System.Int32' && tipo.valor}
              {tipo.propiedad.tipo === 'System.Boolean'
                ? tipo.valor === 'true'
                  ? 'Sí'
                  : tipo.valor === 'false'
                  ? 'No'
                  : 'No definido'
                : ''}
            </AccordionTab>
          );
        })}
      </Accordion>
    </div>
  );
};

export default RowExpansionTemplate;
