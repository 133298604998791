import axios from 'axios';
export default class AlertasMensajesService {
  getAlertasMensajes() {
    return axios
      .get('/api/Expediente/by-mensajes-leidos/false?MaxResultCount=100')
      .then((res) => res.data);
  }

  getAlertasEstado0() {
    return axios
      .get(
        '/api/Expediente/by-estado-documentos?estadoDocumentos=0&MaxResultCount=100'
      )
      .then((res) => res.data);
  }
  getAlertasEstado1() {
    return axios
      .get(
        '/api/Expediente/by-estado-documentos?estadoDocumentos=1&MaxResultCount=100'
      )
      .then((res) => res.data);
  }
}
