const apiUrl = 'https://legacyapi.grupogedesco.com:51443';
//#region USERS
export const login = (data) => {
  return {
    type: 'LOGIN_REQUEST',
    payload: {
      request: {
        method: 'post',
        url: `${process.env.REACT_APP_API_USER_URL}/api/Authentication/Log-in`,
        data: {
          email: data.email,
          password: data.pass,
          product: 'Procuradores',
        },
        failAction: 'LOGIN_FAILURE',
        okAction: 'LOGIN_SUCCESS',
      },
    },
  };
};

export const loginAd = (data) => {
  return {
    type: 'LOGIN_REQUEST',
    payload: {
      request: {
        method: 'post',
        url: `${process.env.REACT_APP_API_USER_URL}/api/Authentication/Log-in-ad`,
        data: {
          username: data.username,
          password: data.pass,
          product: 'Procuradores',
        },
        failAction: 'LOGIN_FAILURE',
        okAction: 'LOGIN_SUCCESS',
      },
    },
  };
};

export const resetToken = (token, refreshToken) => {
  return {
    type: 'TOKEN_REQUEST',
    payload: {
      request: {
        method: 'post',
        url: `${process.env.REACT_APP_API_USER_URL}/api/Authentication/Refresh-token`,
        data: {
          accessToken: token,
          refreshToken: refreshToken,
          product: 'Procuradores',
        },
        failAction: 'LOGIN_FAILURE',
        okAction: 'TOKEN_SUCCESS',
      },
    },
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
    payload: {},
  };
};
//#endregion USERS

export const getUsers = () => {
  return {
    type: 'API_GET_REQUEST',
    payload: {
      request: {
        method: 'get',
        url: `${apiUrl}/api/Usuario`,
        getKey: 'users',
        paging: 'true',
        failAction: 'API_GET_FAILURE',
        okAction: 'API_GET_SUCCESS',
      },
    },
  };
};

export const getUsersOperations = (id) => {
  return {
    type: 'API_GET_REQUEST',
    payload: {
      request: {
        method: 'get',
        url: `${apiUrl}/Operacion/by-id-usuario/${id}`,
        getKey: 'operations',
        paging: 'true',
        failAction: 'API_GET_FAILURE',
        okAction: 'API_GET_SUCCESS',
      },
    },
  };
};

//#region EFECTOS
export const getOperationsEfectos = (id) => {
  return {
    type: 'API_GET_REQUEST',
    payload: {
      request: {
        method: 'get',
        url: `${apiUrl}/api/Efecto/by-id-operacion/${id}`,
        getKey: 'operationsEfectos',
        paging: 'false',
        failAction: 'API_GET_FAILURE',
        okAction: 'API_GET_SUCCESS',
      },
    },
  };
};

export const savePagareMicropymes = () => {
  return {
    type: 'API_POST_REQUEST',
    payload: {
      request: {
        method: 'post',
        url: `${apiUrl}/api/Efecto`,
        getKey: 'setPagareMicropymes',
        paging: 'false',
        failAction: 'API_GET_FAILURE',
        okAction: 'API_GET_SUCCESS',
      },
    },
  };
};
//#endregion EFECTOS

//#region PROCURADORES
export const getExpedientes = (data) => {
  let url = '';
  if (
    data?.Estados?.length &&
    data.Estados.some((estado) => estado === 'Finalizado')
  ) {
    url = '/api/Expediente/by-finalizado/true';
  } else {
    url = '/api/Expediente/by-en-estudio-or-preparado/true';
  }
  return {
    type: 'API_GET_REQUEST',
    payload: {
      request: {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}${url}?IsEnabled=${data.IsEnabled}&Keyword=${data.Keyword}&SortingCriteria=${data.SortingCriteria}&MaxResultCount=${data.MaxResultCount}&SkipCount=${data.SkipCount}`,
        getKey: 'expedientes',
        paging: 'false',
        failAction: 'API_GET_FAILURE',
        okAction: 'API_GET_SUCCESS',
      },
    },
  };
};

export const getExpedientesById = (id) => {
  return {
    type: 'API_GET_REQUEST',
    payload: {
      request: {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/Expediente/${id}`,
        // url: apiUrl+`api/Expediente?Keyword=${data.Keyword}&IsEnabled=${data.IsEnabled}&SortingCriteria=${data.SortingCriteria}&MaxResultCount=${data.MaxResultCount}&SkipCount=${data.SkipCount}`,
        getKey: 'expediente',
        paging: 'false',
        failAction: 'API_GET_FAILURE',
        okAction: 'API_GET_SUCCESS',
      },
    },
  };
};

export const putExpediente = (data) => {
  return {
    type: 'API_PUT_REQUEST',
    payload: {
      request: {
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/api/Expediente/referencia/${data.referencia}/by-id/${data.id}`,
        // url: apiUrl+`api/Expediente/referencia/${data.referencia}/by-id/${data.id}`,
        getKey: 'putExpedienteReferencia',
        paging: 'false',
        failAction: 'API_PUT_FAILURE',
        okAction: 'API_PUT_SUCCESS',
      },
    },
  };
};
//#endregion

export const setTotalNotificaciones = (total) => {
  return {
    type: 'SET_TOTAL_NOTIFICATIONS',
    payload: total,
  };
};

export const deleteState = (text) => {
  return {
    type: 'API_DEL_STATE',
    payload: {
      text,
    },
  };
};

export default {
  login,
  logout,
  getUsers,
  getUsersOperations,
  getOperationsEfectos,
  deleteState,
  savePagareMicropymes,
  getExpedientes,
};
