import { combineReducers } from 'redux';
import userReducer from './userReducer';
import apiReducer from './apiReducer';

const rootReducer = combineReducers(
    {
        userState: userReducer,
        apiState: apiReducer,
    }
)

export default rootReducer;