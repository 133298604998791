import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from '@progress/kendo-react-intl';
import { connect } from 'react-redux';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';

import LoginContainer from './containers/LoginContainer';

import Alertas from './pages/Alertas';
import Historico from './pages/Historico';
import DrawerRouterContainer from './components/DrawerRouterContainer.jsx';
import ExpedientesContainer from './containers/ExpedientesContainer';
import DetalleExpedientesContainer from './containers/DetalleExpedienteContainer';
import PDFViewer from './components/PDFViewer';
import { ErrorBoundary } from './components/errors/ErrorBoundary';
import { Step1, Step2 } from './pages/RecoveryPassword';
import { AppContext } from './AppContext';
import { countries } from './resources/countries';
import { enMessages } from './messages/en-US';
import { frMessages } from './messages/fr';
import { esMessages } from './messages/es';

import './App.scss';

load(
  likelySubtags,
  currencyData,
  weekData,
  frNumbers,
  frLocalCurrency,
  frCaGregorian,
  frDateFields,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  esNumbers,
  esLocalCurrency,
  esCaGregorian,
  esDateFields
);

loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');
loadMessages(enMessages, 'en-US');

const App = (props, { loged }) => {
  const [contextState, setContextState] = React.useState({
    localeId: 'en-US',
    firstName: 'Peter',
    lastName: 'Douglas',
    middleName: '',
    email: 'peter.douglas@progress.com',
    phoneNumber: '(+1) 8373-837-93-02',
    avatar: null,
    country: countries[33].name,
    isInPublicDirectory: true,
    biography: '',
    teamId: 1,
  });
  const onLanguageChange = React.useCallback(
    (event) => {
      setContextState({ ...contextState, localeId: event.value.localeId });
    },
    [contextState, setContextState]
  );
  const onProfileChange = React.useCallback(
    (event) => {
      setContextState({ ...contextState, ...event.dataItem });
    },
    [contextState, setContextState]
  );

  return (
    <div className="app-wrapper">
      <ErrorBoundary>
        <LocalizationProvider language={contextState.localeId}>
          <IntlProvider locale={contextState.localeId}>
            <AppContext.Provider
              value={{ ...contextState, onLanguageChange, onProfileChange }}
            >
              <BrowserRouter>
                <DrawerRouterContainer loged={props.loged}>
                  <Switch>
                    <Route
                      exact={true}
                      path="/login"
                      component={LoginContainer}
                    />
                    <Route
                      exact={true}
                      path="/recovery-password-step1"
                      component={Step1}
                    />
                    <Route
                      exact={true}
                      path="/recovery-password-step2"
                      component={Step2}
                    />
                    <Route
                      exact={true}
                      path="/"
                      component={ExpedientesContainer}
                    />
                    <Route
                      exact={true}
                      path="/archivados"
                      component={ExpedientesContainer}
                    />
                    <Route exact={true} path="/alertas" component={Alertas} />
                    <Route
                      exact={true}
                      path="/historico"
                      component={Historico}
                    />
                    <Route
                      exact={false}
                      path="/expediente-detalle"
                      component={DetalleExpedientesContainer}
                    />
                    <Route
                      exact={true}
                      path="/visor-pdf"
                      component={PDFViewer}
                    />
                  </Switch>
                </DrawerRouterContainer>
              </BrowserRouter>
            </AppContext.Provider>
          </IntlProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loged: state.userState.loged,
});

const AppContainer = connect(mapStateToProps)(App);

export default AppContainer;
