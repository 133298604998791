//reducer snippet

const initialState = {
  fetching: false,
  error: null,
  totalNotifications: 0,
};

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    // ! API GET
    case 'API_GET_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case 'API_GET_SUCCESS':
      return {
        ...state,
        fetching: false,
        error: null,
        ...action.payload,
      };
    case 'API_GET_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    // ! POST STATE
    case 'API_POST_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case 'API_POST_SUCCESS':
      return {
        ...state,
        fetching: false,
        error: null,
        ...action.payload,
      };
    case 'API_POST_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    // ! PUT STATE
    case 'API_PUT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case 'API_PUT_SUCCESS':
      return {
        ...state,
        fetching: false,
        error: null,
        ...action.payload,
      };
    case 'API_PUT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    // ! DELETE STATE
    case 'API_DEL_STATE':
      return {
        ...Object.assign({}, state, { [action.payload.text]: undefined }),
      };
    case 'SET_TOTAL_NOTIFICATIONS':
      return {
        ...state,
        totalNotifications: action.payload,
      };
    default:
      return state;
  }
};

export default apiReducer;
