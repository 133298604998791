import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import Buscador from './Buscador';
import Bell from './Bell';
import { SplitButton } from 'primereact/splitbutton';
import MenuIcon from '@material-ui/icons/Menu';
import {
  faBalanceScale,
  faBullhorn,
  faCalendar,
  faFolder,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'antd';
import logo from '../assets/pct_logotipo_azuloscuro.svg';

export const Header = (props) => {
  const [name, setName] = useState('');
  const [showing, setShowing] = useState(false);
  const items = [
    {
      label: 'Salir',
      icon: 'pi pi-fw pi-user-plus',
      command: () => {
        props.history.push('/');
        props.onRequestLogout();
      },
    },
  ];

  const itemsMenu = [
    {
      name: 'Procedimientos',
      iconSvg: faBalanceScale,
      selected: true,
      hiddenUnlogged: true,
      route: '/',
    },
    {
      name: 'Alertas',
      iconSvg: faBullhorn,
      hiddenUnlogged: true,
      route: '/alertas',
    },
    {
      name: 'Historico',
      iconSvg: faCalendar,
      hiddenUnlogged: true,
      route: '/historico',
    },
    {
      name: 'Archivados',
      iconSvg: faFolder,
      hiddenUnlogged: true,
      route: '/archivados',
    },
  ];

  useEffect(() => {
    if (props.loged) {
      setName(sessionStorage.getItem('userName'));
    } else {
      props.history.push('/');
      props.onRequestLogout();
    }
  }, [props.loged]);

  return (
    <div className='app-header'>
      <div className='col-12 header-content flex-container'>
        <Link to='/'>
          <img className='app-logo' alt='Procurarte' src={logo} />
        </Link>
        <MenuIcon
          style={{ cursor: 'pointer' }}
          className='menu-icon'
          onClick={() => {
            setShowing(true);
          }}
        />
        <div className='header-left'>
          <Buscador properties={props} />
        </div>

        <div className='header-right'>
          <div className='flex-container bell-icon-container'>
            <span>
              <Bell router={props} />
            </span>
          </div>
          <div className='flex-container'>
            <SplitButton
              label={name}
              icon='pi pi-fw pi-user'
              model={items}
              style={{ float: 'right', marginRight: '15px' }}
            ></SplitButton>
          </div>
        </div>
        <Sidebar
          visible={showing}
          position='left'
          showCloseIcon={true}
          modal={false}
          closeOnEscape={true}
          baseZIndex={999}
          dismissable={true}
          onHide={() => setShowing(false)}
        >
          <Menu
            inlineCollapsed={false}
            className='menu-movil'
            mode='inline'
            theme='light'
          >
            {itemsMenu.map(
              (item) =>
                !item.hidden && (
                  <Menu.Item
                    key={item.name}
                    onClick={() => {
                      props.history.push(item.route);
                      setShowing(false);
                    }}
                  >
                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </Menu.Item>
                )
            )}
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
};

Header.displayName = 'Header';
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
