import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import ExpedienteService from '../service/expediente.service';
import Autosuggest from 'react-autosuggest';

const Buscador = () => {
  const history = useHistory();
  const [value, setValue] = useState('');
  const [suggestions, setSuggestion] = useState([]);
  const [redirect] = useState(false);
  const [paramExpediente, setParamExpediente] = useState({
    Keyword: '',
    IsEnabled: true,
    SortingCriteria: '',
    MaxResultCount: 9,
    SkipCount: 0,
    Estados: ['Preparado', 'EnEstudio'],
  });

  const expedienteService = new ExpedienteService();

  useEffect(() => {
    const getSuggestions = async () => {
      let newSuggestions = await expedienteService.getExpedientes(
        paramExpediente
      );
      newSuggestions = newSuggestions.items.map((x) => {
        return {
          id: x.id,
          procedimiento: x.procedimiento,
          juzgado: x.juzgado,
          referencia: x.referencia,
          demandado: x.demandado,
        };
      });
      setSuggestion(newSuggestions);
    };
    getSuggestions();
  }, [paramExpediente]);

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    return (
      (suggestion.referencia ? suggestion.referencia + ' - ' : '') +
      suggestion.procedimiento +
      ' - ' +
      suggestion.juzgado +
      ' - ' +
      suggestion.demandado
    ).toUpperCase();
  };

  // Use your imagination to render suggestions.
  // renderSuggestion = suggestion => (
  //     <div>
  //         prueba
  //     </div>
  // );

  const renderSuggestion = (suggestion) => {
    return (
      <span>
        {(
          (suggestion.referencia ? suggestion.referencia + ' - ' : '') +
          suggestion.procedimiento +
          ' - ' +
          suggestion.juzgado +
          ' - ' +
          suggestion.demandado
        ).toUpperCase()}
      </span>
    );

    // return <span>{suggestion.slice(suggestion.indexOf('- ')+1)}</span>;
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    setParamExpediente((params) => ({ ...params, Keyword: value }));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setValue('');
    // console.log(suggestion)
    expedienteService.getExpedienteById(suggestion.id).then((data) => {
      history.replace({
        pathname: '/expediente-detalle/' + data.id,
        state: { detail: data },
      });
    });
  };

  const inputProps = {
    placeholder: 'Busca un procedimiento',
    value,
    onChange: onChange,
  };

  if (redirect) {
    return <Redirect push to="/expediente-detalle" />;
  }
  return (
    <div className="autocomplete-template">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
    </div>
  );
};
export default Buscador;
