import axios from 'axios';

export default class PropiedadService {
  getPropiedadByTipoId(idTipoDoc) {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/Propiedad/by-tipo-documento-id/${idTipoDoc}?MaxResultCount=100`
      )
      .then((res) => res.data);
  }
}
