import { connect } from "react-redux";
import Login from "../pages/Login";

import { login } from "../store/actions/actions";

const mapStateToProps = (state) => {
  return {
    loged: state.userState.loged,
    error: state.userState.error,
    fetching: state.userState.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestLogin: (data) => {
      dispatch(login(data));
    },
  };
};

let LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
