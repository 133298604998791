import React, { Component, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DetalleExpedienteService from '../service/detalleExpedienteService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const TarjetasCampana = () => {
  const history = useHistory();
  const [docNuevos, setDocNuevos] = useState([]);
  const [noLeidos, setNoLeidos] = useState([]);
  const [procNuevos, setProcNuevos] = useState([]);
  const [docRechazados, setDocRechazados] = useState([]);
  const [totalProcNuevos, setTotalProcNuevos] = useState(0);
  const [totalNuevos, setTotalNuevos] = useState(0);
  const [totalDocRechazados, setTotalDocRechazados] = useState(0);
  const [totalDocNuevos, setTotalDocNuevos] = useState(0);
  const [selectedExpediente, setSelectedExpediente] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);

  const detalleExpedienteService = new DetalleExpedienteService();

  useEffect(() => {
    getNoLeidos();
    getProcedimientosNuevos();
    getDocsRechazados();
    getDocsNuevos();
  }, []);

  const getNoLeidos = () => {
    setLoading1(true);
    detalleExpedienteService
      .getMensajesNoLeidos()
      .then((data) => {
        const arr = [];
        Object.keys(data).forEach((key) => {
          //if(arr.length<3){
          //setNoLeidos(...noLeidos,{procedimiento: data[key].procedimiento, amount:  data[key].amount, id: data[key].expedienteId});
          arr.push({
            procedimiento: data[key].procedimiento,
            amount: data[key].amount,
            id: data[key].expedienteId,
          });
          //}
        });
        setNoLeidos(arr);
        setLoading1(false);
      })
      .catch(() => {
        setLoading1(false);
      });
  };

  const getProcedimientosNuevos = () => {
    setLoading2(true);
    detalleExpedienteService
      .getProcedimientosNuevos()
      .then((data) => {
        const arr = [];
        Object.keys(data.items).forEach((key) => {
          //if(arr.length<3){
          arr.push({
            key: key,
            procedimiento: data.items[key].procedimiento,
            id: data.items[key].id,
          });
          //}
        });
        setProcNuevos(arr);
        setTotalProcNuevos(data.totalCount);
        setLoading2(false);
      })
      .catch(() => {
        setLoading2(false);
      });
  };

  const getDocsRechazados = () => {
    setLoading3(true);
    detalleExpedienteService
      .getDocsRechazados()
      .then((data) => {
        const arr = [];
        Object.keys(data.items).forEach((key) => {
          //if(arr.length<3){
          arr.push({
            key: key,
            procedimiento: data.items[key].procedimiento,
            id: data.items[key].id,
          });
          //}
        });

        setDocRechazados(arr);
        setTotalDocRechazados(data.items.length);
        setLoading3(false);
      })
      .catch(() => {
        setLoading3(false);
      });
  };

  const getDocsNuevos = () => {
    setLoading4(true);
    detalleExpedienteService
      .getDocsAbogadoNuevos()
      .then((data) => {
        const arr = [];
        Object.keys(data.items).forEach((key) => {
          //if(arr.length<3){
          arr.push({
            key: key,
            procedimiento: data.items[key].procedimiento,
            id: data.items[key].id,
          });
          //}
        });
        setDocNuevos(arr);
        setTotalDocNuevos(data.items.length);
        setLoading4(false);
      })
      .catch(() => {
        setLoading4(false);
      });
  };

  const Loader = () => {
    return (
      <div className="containerBellLoading">
        <div className="tarjetaBellLoading"></div>
      </div>
    );
  };

  const TarjeteroGenerico = ({ elementos, titulo }) => {
    return (
      <div className="tarjetaBellWrapper2">
        <span className="p-badge">
          {elementos.length > 0 ? elementos.length : 0}
        </span>
        <div className="titulosTarjetas">
          {' '}
          <span className="titulosTarjetasTitulo">{titulo}</span>
        </div>

        {elementos.length > 0
          ? elementos.slice(0, 3).map((item) => (
              <div
                key={item.id}
                className="tarjetaBellContent"
                onClick={() => {
                  history.replace({
                    pathname: '/expediente-detalle/' + item.id,
                    state: { detail: item },
                  });
                }}
              >
                <span className="numExpedienteAlerta">
                  {item.procedimiento}
                </span>
                <br></br> {item.amount && <span> {item.amount} mensajes</span>}
              </div>
            ))
          : null}
        <span
          className="verTodosMensajes"
          onClick={() => {
            history.replace({
              pathname: '/alertas',
              state: { table: 'no-leidos' },
            });
          }}
        >
          Ver todos
        </span>
      </div>
    );
  };

  const SinAlertas = () => {
    return (
      <div className="tarjetaBellWrapper2">
        <div className="titulosTarjetas">
          <span className="titulosTarjetasTitulo"> </span>
        </div>

        <div className="tarjetaBellContent">Sin Alertas</div>
        <span
          className="verTodosMensajes"
          onClick={() => {
            history.replace({
              pathname: '/alertas',
              state: { table: 'all' },
            });
          }}
        >
          Ver todos
        </span>
      </div>
    );
  };

  return (
    <div>
      <span className="bellTitle">
        <div className="row">
          <div className="col-md-2">
            <FontAwesomeIcon className="bell" icon={faBell} />
          </div>
          <div className="col-md-6">
            <h3 className="cabeceraAlertaBell">Centro de Notificaciones</h3>
          </div>
          <div className="col-md-4"></div>
        </div>
      </span>
      <br></br>
      {loading1 ? <Loader /> : null}
      {loading2 ? <Loader /> : null}
      {loading3 ? <Loader /> : null}
      {loading4 ? <Loader /> : null}
      {noLeidos.length > 0 ? (
        <TarjeteroGenerico
          elementos={noLeidos}
          titulo={'Procedimientos con mensajes'}
        />
      ) : null}
      {procNuevos.length > 0 ? (
        <TarjeteroGenerico
          elementos={procNuevos}
          titulo={'Procedimientos nuevos'}
        />
      ) : null}
      {docRechazados.length > 0 ? (
        <TarjeteroGenerico
          elementos={docRechazados}
          titulo={'Documentos rechazados'}
        />
      ) : null}
      {docNuevos.length > 0 ? (
        <TarjeteroGenerico
          elementos={docNuevos}
          titulo={'Documentos para presentar'}
        />
      ) : null}
      {noLeidos.length === 0 &&
      procNuevos.length === 0 &&
      docRechazados.length === 0 &&
      docNuevos.length === 0 ? (
        <SinAlertas />
      ) : null}
    </div>
  );
};
export default TarjetasCampana;
