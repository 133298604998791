import axios from 'axios';
export default class MensajesService {
  getMensajes(expedienteId) {
    return axios
      .get(`/api/Mensaje/by-expediente-id/${expedienteId}?MaxResultCount=100`)
      .then((res) => res.data);
  }

  markAsRead(expedienteId, identityId) {
    return axios.put(
      `/api/Mensaje/leido/true/by-expediente-id/${expedienteId}/by-identity-id/${identityId}`
    );
  }

  postMessage(mensaje, usuarioId, expedienteId) {
    return axios.post('/api/Mensaje', {
      expedienteId: expedienteId,
      usuarioId: usuarioId,
      texto: mensaje,
    });
  }
}
