import { connect } from 'react-redux'
import DetalleExpediente from '../pages/DetalleExpediente';
import * as action from '../store/actions/actions';

const mapStateToProps = (state) => ({
    fetching: state.apiState.fetching,
    error: state.apiState.error? state.apiState.error:null,
    expedientes: state.apiState.expedientes? state.apiState.expedientes:[],
    putExpediente: state.apiState.putExpediente,
    expediente: state.apiState.expediente? state.apiState.expediente:null
});

const mapDispatchToProps = (dispatch) => {    
    return {
        onLoad: data => {
            dispatch(action.getExpedientes(data))
        },
        putExpediente: data => {
            dispatch(action.putExpediente(data))
        },
        getExpedienteById: id => {
            dispatch(action.getExpedientesById(id))
        }
    }
}

const DetalleExpedientesContainer = connect(mapStateToProps, mapDispatchToProps)(DetalleExpediente);

export default DetalleExpedientesContainer;