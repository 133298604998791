import React from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { SelectButton } from 'primereact/selectbutton';

import { calendarES } from 'utils/utils';
import { idsPropiedadesNoRequeridas } from 'utils/constants';

const FormTemplate = ({
  detalleCampos,
  camposArray,
  setCamposArray,
  formState,
  setFormState,
}) => {
  const addElementos = (id, valor, propiedadId) => {
    const element = camposArray.find((x) => x.PropiedadId === id);
    let arrayGeneral = [...camposArray];
    // arrayGeneral = arrayGeneral.filter((x) => x.PropiedadId !== id);
    let totalObligatoriosIns = formState.camposObligatoriosIns;

    /***
     * Para habilitar el filePond, tienen que estar todos los campos obligatorios completados.
     * Para esta funcionalidad, tenemos precalculados en state.camposObligatoriosNum el número de campos totales obligatorios
     * Por otro lado, tenemos que ir calculando cuantos de esos campos oblogatorios estamos rellenando.
     * Ese cáculo lo tenemos en state.camposObligatoriosIns y hacemos la lógica en este punto.
     *
     * Importante: EL campo fecha plazo NO es obligatorio, por eso no lo contamos si nos llega.
     *
     * Finalmente, si el número de campos obligatorios rellenos es igual al número de campos obligatorios esperados, mostramos el filepond
     *
     */
    if (element) {
      arrayGeneral.pop(element);
      //El campo fecha, con id 2, no es obligatorio
      if (!idsPropiedadesNoRequeridas.includes(id)) {
        totalObligatoriosIns = totalObligatoriosIns - 1;
      }
    }

    if (valor !== '') {
      var objeto = { PropiedadId: id, Valor: valor, idPropiedad: propiedadId };
      arrayGeneral.push(objeto);
      //El campo fecha, con id 2, no es obligatorio
      if (!idsPropiedadesNoRequeridas.includes(id)) {
        totalObligatoriosIns = totalObligatoriosIns + 1;
      }
    }
    setFormState((fs) => ({
      ...fs,
      [id]: valor,
      camposObligatoriosIns: totalObligatoriosIns,
    }));
    setCamposArray(arrayGeneral);
  };

  const selectOptions = [
    { name: 'Sí', value: true },
    { name: 'No', value: false },
  ];

  return (
    <div>
      {formState.replace ? (
        <Button
          label="Cancelar"
          className="p-button-danger"
          onClick={() => {
            setFormState((fs) => ({ ...fs, replace: null }));
          }}
        />
      ) : (
        ''
      )}
      {detalleCampos.map((tipo, index) => {
        let valorCampo = formState.replace
          ? formState.replace.valorPropiedades.find(
              (x) => x.propiedadId === tipo.id
            )
          : null;
        if (!valorCampo) {
          if (formState[tipo.id]) {
            valorCampo = { id: tipo.id, valor: formState[tipo.id] };
          } else {
            valorCampo = {};
            valorCampo.valor = null;
          }
        }

        if (tipo.tipo === 'System.String' && tipo.asignablePor !== 1) {
          return tipo.longitudMaxima === 255 ? (
            <div key={index} className="p-field p-col-12 p-md-12">
              <label style={{ marginRight: '10px', width: '200px' }}>
                {tipo.nombre}
              </label>

              <InputTextarea
                className="textAreaForm"
                rows={5}
                cols={70}
                value={valorCampo?.valor || ''}
                onChange={(e) => {
                  addElementos(
                    tipo.id,
                    e.target.value ?? '',
                    valorCampo ? valorCampo.id : null
                  );
                }}
              ></InputTextarea>
            </div>
          ) : (
            <div key={index} className="p-field p-col-12 p-md-12">
              <label style={{ marginRight: '10px', width: '200px' }}>
                {tipo.nombre}
              </label>
              <InputText
                value={formState.tituloValue || ''}
                placeHolder={valorCampo ? valorCampo.valor : ''}
                className="col-6"
                onChange={(e) => {
                  addElementos(tipo.id, e.target.value ?? '', valorCampo.id);
                }}
              ></InputText>
            </div>
          );
        }
        if (tipo.tipo === 'System.Boolean' && tipo.asignablePor !== 1) {
          return (
            <div key={index} className="p-field p-col-12 p-md-12">
              <label
                style={{ marginRight: '10px', marginBottom: '20px' }}
                htmlFor="boolean"
              >
                {tipo.nombre}
              </label>
              <SelectButton
                id="boolean"
                value={formState[tipo.id]}
                optionLabel="name"
                options={selectOptions}
                onChange={(e) => {
                  setFormState((fs) => ({ ...fs, [tipo.id]: e.value }));
                  addElementos(tipo.id, e.value ?? '', valorCampo.id);
                }}
              />
            </div>
          );
        }
        if (tipo.tipo === 'System.DateTime' && tipo.asignablePor !== 1) {
          return (
            <div key={index} className="p-field p-col-12 p-md-12">
              <label
                style={{ marginRight: '10px', width: '200px' }}
                htmlFor="fechaPlazo"
              >
                {tipo.nombre}
              </label>
              <Calendar
                showButtonBar
                showIcon
                id="fechaPlazo"
                value={
                  valorCampo.valor
                    ? new Date(valorCampo.valor.split('/').reverse().join('/'))
                    : null
                }
                onChange={(e) => {
                  const dateToLocale =
                    e.value?.toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }) ?? '';
                  addElementos(tipo.id, dateToLocale, valorCampo.id);
                }}
                onClearButtonClick={(e) => {
                  valorCampo.valor = '';
                }}
                locale={calendarES}
                dateFormat="dd/mm/yy"
              />
            </div>
          );
        }

        if (tipo.tipo === 'System.Int32' && tipo.asignablePor !== 1) {
          return (
            <div key={index} className="p-field p-col-12 p-md-12">
              <label style={{ marginRight: '10px' }}>{tipo.nombre}</label>
              <InputMask
                value={formState[tipo.id] || ''}
                mask="9999"
                slotChar="yyyy"
                className="input--year"
                onChange={(e) => {
                  const actualYear = new Date().getFullYear();
                  const currentValue = e.target.value;
                  if (
                    Number.isInteger(+currentValue) &&
                    currentValue.length === 4 &&
                    +currentValue <= actualYear &&
                    +currentValue >= 2018
                  ) {
                    addElementos(tipo.id, currentValue, valorCampo.id);
                  } else if (
                    Number.isInteger(+currentValue) &&
                    currentValue.length === 4
                  ) {
                    setFormState((fs) => ({ ...fs, [tipo.id]: '' }));
                    addElementos(tipo.id, '', valorCampo.id);
                  }
                }}
              ></InputMask>
            </div>
          );
        }
        return false;
      })}
    </div>
  );
};

export default FormTemplate;
