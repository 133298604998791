import axios from 'axios';
// const apiUrl="https://legacyapi.grupogedesco.com:51443/";
// const apiUrl="https://legacyapi.grupogedesco.com:51443";
//const rookie="http://rookie:51438/";
export default class DetalleExpedienteService {
  getTipoDocumentoByExpedienteId(idExpediente) {
    return axios
      .get(`/api/ConfiguracionExpediente/by-expediente-id/${idExpediente}`)
      .then((res) => res.data);
  }

  getDiligenciasByBienId(idExpediente, bienId) {
    return axios
      .get(`/api/ConfiguracionExpediente/by-expediente-id/${idExpediente}/by-bien-id/${bienId}`)
      .then((res) => res.data);
  }

  getCategoriasTipoDocumento() {
    return axios
      .get('/api/CategoriaTipoDocumento?MaxResultCount=100')
      .then((res) => res.data);
  }
  getLastDocumentosSubidos(expedienteId, tipoDocumentoId) {
    return axios
      .get(
        '/api/Documento/last-one/by-expediente-id/' +
          expedienteId +
          '/by-tipo-documento-id/' +
          tipoDocumentoId
      )
      .then((res) => res);
  }
  getAllDocumentosSubidos(expedienteId, tipoDocumentoId) {
    return axios
      .get(
        `/api/Documento/by-expediente-id/${expedienteId}/by-tipo-documento-id/${tipoDocumentoId}?MaxResultCount=100`
      )
      .then((res) => res);
  }

  getDocumentosSubidos(expedienteId, tipoDocumentoId) {
    return axios
      .get(
        `/api/DocumentoAbogado/by-expediente-id/${expedienteId}/by-tipo-documento-id/${tipoDocumentoId}?MaxResultCount=100`
      )
      .then((res) => res.data);
  }

  getMensajesNoLeidos() {
    return axios
      .get('/api/Mensaje/by-leido/false?MaxResultCount=100')
      .then((res) => res.data);
  }
  getProcedimientosNuevos() {
    return axios
      .get('/api/Expediente/by-leido/false?MaxResultCount=100')
      .then((res) => res.data);
  }

  getProcedimientosLegacy(expedienteId) {
    return axios
      .get('/api/DocumentoLegacy/by-expediente-id/' + expedienteId)
      .then((res) => res.data);
  }

  getProcedimientosLegacyTipoDoc(expedienteId, tipoDocumentoId) {
    return axios
      .get(
        `/api/DocumentoLegacy/by-expediente-id/${expedienteId}/by-tipo-documento-id/${tipoDocumentoId}?MaxResultCount=100`
      )
      .then((res) => res.data);
  }

  getDocsRechazados() {
    return axios
      .get(
        '/api/Expediente/by-estado-documentos?estadoDocumentos=1&MaxResultCount=100'
      )
      .then((res) => res.data);
  }

  getDocsNuevos() {
    return axios
      .get(
        '/api/Expediente/by-estado-documentos?estadoDocumentos=0&MaxResultCount=100'
      )
      .then((res) => res.data);
  }

  getDocsAbogadoNuevos() {
    return axios
      .get(
        '/api/Expediente/by-documentos-abogado-leidos/false?MaxResultCount=100'
      )
      .then((res) => res.data);
  }

  guardarDoc(event, id) {
    var datos = new FormData();
    var imagedata = event;
    datos.append('rawFile', imagedata);

    return axios
      .put('/api/Documento/upload-documento/by-id/' + id, datos)
      .then((res) => res.data);
  }

  guardarForm(form, event) {
    var datos = new FormData();

    form.ValorPropiedades.map((item, index) => {
      datos.append(
        `CreationDto.ValorPropiedades[${index}].PropiedadId`,
        item.PropiedadId
      );
      datos.append(`CreationDto.ValorPropiedades[${index}].Valor`, item.Valor);

      return item;
    });

    datos.append('CreationDto.ExpedienteId', form.expedienteId);
    datos.append('CreationDto.TipoDocumentoId', form.tipoDocumentoId);
    //datos.append('CreationDto.ValorPropiedades',objJson)
    event.forEach((element) => {
      datos.append('FormFiles', element.file);
    });

    return axios.post('/api/Documento/file', datos).then((res) => res.data);
  }

  updateDoc(form, event) {
    var datos = new FormData();
    var imagedata = event;
    datos.append('rawFile', imagedata);

    return axios
      .put(`/api/Documento/file/by-id/${form.id}`, datos)
      .then((res) => res.data);
  }

  putEditarCampos(campos) {
    var data = {
      id: campos.idPropiedad,
      valor: campos.Valor,
    };

    return axios
      .put(`/api/ValorPropiedad`, data)
      .then((res) => {
        return res.data;
      })
      .catch((response) => console.log('error', response));
  }

  getTotalNotificaciones() {
    return axios.get('/api/Notificacion/total-count').then((res) => res.data);
  }

  getDocumentoAbogadoNoLeidoByExpedienteId(expedienteId) {
    return axios
      .get(
        `/api/DocumentoAbogado/by-leido/false/by-expediente-id/${expedienteId}?MaxResultCount=100`
      )
      .then((res) => res.data);
  }

  setExpedienteLeido(idExpediente) {
    return axios
      .put('/api/Expediente/leido/by-id/' + idExpediente)
      .then((res) => res.data);
  }
}
