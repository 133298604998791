import React, { useState, useEffect } from 'react';
import '../styles/login.scss';

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import classNames from 'classnames/bind';
import juridico from '../assets/title2.png';
import logo from '../assets/pct_logotipo_blanco.svg';
import { Beetle as ButtonLoader } from 'react-button-loaders';
import { Link, Redirect } from 'react-router-dom';

const InputEmail = (props) => {
  const { value, onChange, name, error } = props;
  var inputClass = classNames({
    'input-text-login': true,
    'p-invalid p-mr-2': error ? true : false,
  });
  return (
    <InputText
      className={inputClass}
      id='email'
      value={value}
      name={name}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
const InputPass = (props) => {
  const { value, onChange, name, error } = props;
  var inputClass = classNames({
    'input-text-login': true,
    'p-invalid p-mr-2': error ? true : false,
  });
  return (
    <Password
      className={inputClass}
      feedback={false}
      id='pass'
      value={value}
      name={name}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

function Login(
  { onRequestLogin, loged, error, fetching }
) {
  const { handleSubmit, errors, control } = useForm();
  const [emailOrUserName, setEmailOrUserName] = useState('');
  const [pass, setPass] = useState('');
  const [inputName, setInputName] = useState('email');
  const [inputLabel, setInputLabel] = useState('');

  useEffect(() => {
    setInputName('email');
    setInputLabel('Email');
  }, []);

  const onSubmit = (data) => {
    onRequestLogin(data);
  };

  const onError = (errors, e) => {
    // console.log('error',errors)
  };

  if (loged) {
    return <Redirect to='/' />;
  }

  return (
    <div className='row login-base'>
      <div className='col-6 left-col'>
        <div className='frame-1'>
          <img className='group-6' src={logo} alt='procurarte' />
        </div>
        <div className='la-plataforma-de-acc font-class-2'>
          La Plataforma de Acceso a Colaboradores Externos
        </div>
        <div className='la-plataforma-de-acc font-class-2' />
        <p className='nuestra-empresa-trat font-class-7'>
          <span className='span1-vWZsXm'>
            Nuestra empresa tratará sus datos con la finalidad de que acceda al
            área cliente.{' '}
          </span>
          <span className='span2-vWZsXm'>Derechos y más info.</span>
        </p>
      </div>
      <div className='col-sm-12 col-lg-6 right-col'>
        <div className='row align-items-center justify-content-center right-row'>
          <div className='mobile-logo-container'>
            <img className='mobile-logo' src={logo} alt='procurarte' />
          </div>
          <div className='col-12 form-wrapper'>
            <h2 style={{ fontSize: '42px' }}>Acceso usuarios</h2>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className='row login-form'>
                <div className='col-12 input-block-form'>
                  <label htmlFor={inputName} className='p-d-block'>
                    {inputLabel}
                  </label>
                  <Controller
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <InputEmail
                        onChange={(e) => onChange(e)}
                        checked={value}
                        inputRef={ref}
                        name={name}
                        error={errors ? errors.email : null}
                      />
                    )}
                    value={emailOrUserName}
                    onChange={([e]) => {
                      setEmailOrUserName(e);
                      return { value: e };
                    }}
                    rules={{ required: true }}
                    defaultValue={emailOrUserName}
                    name={inputName}
                    control={control}
                  />
                  {errors && errors[inputName] && (
                    <small id='pass' className='p-invalid'>
                      El usuario es obligatorio
                    </small>
                  )}
                </div>
                <div className='col-12 input-block-form'>
                  <label htmlFor='pass' className='p-d-block'>
                    Contraseña
                  </label>
                  <Controller
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <InputPass
                        onChange={(e) => onChange(e)}
                        checked={value}
                        inputRef={ref}
                        name={name}
                        error={errors ? errors.pass : null}
                      />
                    )}
                    value={pass}
                    onChange={([e]) => {
                      setPass(e);
                      return { value: e };
                    }}
                    rules={{
                      required: true,
                    }}
                    defaultValue={pass}
                    name='pass'
                    control={control}
                  />
                  {errors.pass && (
                    <small id='pass' className='p-invalid'>
                      La contraseña es obligatoria
                    </small>
                  )}
                </div>
                <div className='col-12 button-block-form'>
                  <ButtonLoader
                    style={{
                      textTransform: 'none',
                      backgroundColor: '#558DD2',
                      border: 'none',
                      boxShadow: 'none',
                      width: !fetching ? '100%' : '30%',
                      outline: 'none',
                    }}
                    state={fetching ? 'loading' : ''}
                    bgColor={'#2196F3'}
                    bgLoading={'#2196F3'}
                    bgWhenFinish={'#2196F3'}
                    className='button-login'
                    type='submit'
                    label='Acceder'
                  >
                    Acceder
                  </ButtonLoader>
                  <Link to='/recovery-password-step1'>
                    Recuperar contraseña
                  </Link>
                </div>
              </div>
            </form>
            <div className='col-12' style={{ width: '100%', height: '30px' }}>
              {error && (
                <>
                  <small className='p-invalid'>
                    El usuario o la contraseña no son correctos.
                  </small>
                  <small className='p-invalid d-block mt-2'>
                    Si ya estabas registrado en la plataforma, por favor, pulsa
                    el enlace de &quot;Recuperar contraseña&quot; y sigue los
                    pasos que te indican.
                  </small>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
