import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from 'primereact/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { setTotalNotificaciones } from 'store/actions/actions';
import useLocationChange from 'utils/useLocationChange';
import usePreciseTimer from 'utils/useTimer';
import DetalleExpedienteService from '../service/detalleExpedienteService';
import TarjetasCampana from './TarjetasCampana';

const detalleExpedienteService = new DetalleExpedienteService();

const Bell = () => {
  const [showing, setShowing] = useState(false);
  const [totalAlertas, setTotalAlertas] = useState(0);
  const dispatch = useDispatch();
  const { totalNotifications } = useSelector((state) => state.apiState);

  const getTotalNotificaciones = useCallback(() => {
    detalleExpedienteService.getTotalNotificaciones().then((data) => {
      dispatch(setTotalNotificaciones(data));
    });
  }, [dispatch]);

  usePreciseTimer(getTotalNotificaciones, 60000, true);

  useLocationChange((location) => {
    getTotalNotificaciones();
  });

  useEffect(() => {
    setTotalAlertas(totalNotifications);
  }, [totalNotifications]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      e.preventDefault();
      if (showing) {
        setShowing(false);
      }
      e.stopPropagation();
    };
    if (showing) {
      document.addEventListener('click', handleClickOutside, false);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [showing]);

  const onBellClick = () => {
    setShowing(!showing);
  };

  return (
    <div className="bellContainer">
      <span className="p-overlay-badge" onClick={onBellClick}>
        {totalAlertas > 0 ? (
          <FontAwesomeIcon className="bell" icon={faBell} />
        ) : (
          <FontAwesomeIcon className="bell bell-disabled" icon={faBell} />
        )}
        {totalAlertas > 0 ? (
          <span className="p-badge mainBadge">{totalAlertas}</span>
        ) : (
          ''
        )}
      </span>
      <span onClick={() => setShowing(false)}>
        <Sidebar
          dismissable={true}
          visible={showing}
          position="right"
          showCloseIcon={true}
          modal={false}
          closeOnEscape={true}
          baseZIndex={999}
          onHide={() => setShowing(false)}
        >
          <TarjetasCampana />
        </Sidebar>
      </span>
    </div>
  );
};

export default Bell;
