import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hasError: false,
      error: false,
      errorInfo: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de
    // repuesto
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de reporte de errores
    // logErrorToMyService(error, errorInfo);
    this.setState({ errorInfo: errorInfo, error: error });
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return (
        <div id="error-boundary" className="error-wall load-error">
          <div className="error-container">
            <h1>oh no...</h1>
            <h3>We have had an error</h3>
            <h4>Error 404</h4>
            <p>Sorry...please check back (click refresh) in just a moment.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
