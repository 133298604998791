import React, { useEffect,useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AlertasMensajesService from "../service/alerta.service";
import DetalleExpedienteService from "../service/detalleExpedienteService"

const alertasMensajesService = new AlertasMensajesService();
const detalleExpedienteService = new DetalleExpedienteService();
const DataTableAlertasMensajes = () => {
    const history = useHistory();
    const [alertasMensajes, setAlertasMensajes] = useState([]);
    const [docNuevos, setDocNuevos] = useState([]);
    const [docRechazados, setDocRechazados] = useState([]);
    const [procNuevos, setProcNuevos] = useState([]);
    const [selected] = useState(history.location.state?history.location.state.table : "");    

    useEffect(() => {
        alertasMensajesService.getAlertasMensajes().then((data) => {            
            setAlertasMensajes(data.items);
        });

        detalleExpedienteService.getDocsAbogadoNuevos().then((data) => {            
            setDocNuevos(data.items);   
        });

        alertasMensajesService.getAlertasEstado1().then((data) => {
            setDocRechazados(data.items); 
        });

        detalleExpedienteService.getProcedimientosNuevos().then((data) => {            
            setProcNuevos(data.items);             
        });
    }, []);

    const onRowSelect = (event) => {
        history.push({
            pathname: '/expediente-detalle/'+event.data.id,
            state: { detail: event.data }
        });
    }

    const CompTablaGenerica = ({clase, titulo, data, paginable})=>{

        return (
            <div id={'tabla-'+clase} className={'datatable-templating datatable-responsive ' + (selected===clase ? " selected " : "") }>
                <h2 className="expedientes-title">{titulo}</h2>
                <DataTable value={data}
                    lazy
                    onRowSelect={onRowSelect}
                    selectionMode="single" dataKey="id"
                >
                    <Column field="id" header="ID" style={{ display: 'none' }}></Column>
                    <Column field="demandado" header="Demandado"></Column>
                    <Column field="juzgado" header="Juzgado"></Column>
                    <Column field="procedimiento" header="Procedimiento"></Column>
                </DataTable>
            </div>
        )
    }

    return (
            <div id="alertas">

                {alertasMensajes.length > 0 && <CompTablaGenerica clase={"mensajes-no-leidos"} titulo={"Procedimientos con Mensajes"} data={alertasMensajes} />}
                {procNuevos.length > 0 && <CompTablaGenerica clase={"proc-nuevos"} titulo={"Procedimientos nuevos"} data={procNuevos} />}
                {docNuevos.length > 0 && <CompTablaGenerica clase={"doc-nuevos"} titulo={"Documentación para presentar"} data={docNuevos} />}
                {docRechazados.length > 0 && <CompTablaGenerica clase={"doc-rechazados"} titulo={"Documentos Rechazados"} data={docRechazados} />}
                {alertasMensajes.length === 0 && procNuevos.length === 0 && docNuevos.length === 0 && docRechazados.length === 0 &&(
                <div id="sin-alertas" className={'datatable-templating datatable-responsive '}>
                    <h2 className="expedientes-title">Sin alertas</h2>                
                </div>)}
            </div>        
                );
}
export default DataTableAlertasMensajes;