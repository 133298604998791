import React from 'react';
import { Button } from 'primereact/button';
import DocumentosService from 'service/documentos.service';

const GetBotonPdf = ({ rowData, setBase64 }) => {
  if (rowData.documentoLegacyId) {
    return (
      <Button
        onClick={(e) => {
          var documentosService = new DocumentosService();

          documentosService
            .getFileB64Legacy(rowData.documentoLegacyId)
            .then((data) => {
              setBase64(data);
            });
        }}
        icon="pi pi-eye"
        className="p-button p-component p-button-icon-only"
      />
    );
  } else {
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        <Button
          onClick={(e) => {
            var documentosService = new DocumentosService();
            
            if (!rowData.expedienteId) {
              documentosService
                .getDocumentoAbogadoIndice(
                  rowData.expedienteId,
                  rowData.tipoDocumentoId
                )
                .then((data) => {
                  setBase64(data);
                });
            } else {
              documentosService.getFileB64(rowData.id).then((data) => {
                setBase64(data);
              });
            }
          }}
          icon="pi pi-eye"
          className={rowData.leido ? 'boton-leido' : 'boton-noleido'}
        />
        {/* }} icon="pi pi-eye" className={"boton-noleido"}/> */}
      </span>
    );
  }
};

export default GetBotonPdf;
