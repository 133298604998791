import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import RecoveryPasswordService from '../../service/recovery-password-service';
import { useHistory, useLocation } from 'react-router-dom';

const recoveryPasswordService = new RecoveryPasswordService();

const ChangePasswordForm = () => {
    const [canSubmit, setCanSubmit] = useState(false);
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const history = useHistory();
    const { register, errors, handleSubmit, watch, formState, getValues, setError, clearErrors } = useForm({
        mode: 'onChange'
    });
    const location = useLocation();
    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {

        setIsSubmitted(true)
        recoveryPasswordService.resetPassword(data, token, userId).then(() => {
            Swal.fire({
                title: 'Éxito!',
                text: 'Contraseña cambiada correctamente',
                icon: 'success',
            }).then(() => {
                history.push('/');
            })
        })
            .catch(() => {
                setIsSubmitted(false);
            })
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setUserId(params.get('user'));
        setToken(encodeURIComponent(params.get('token')));
    }, [window.location])

    useEffect(() => {
        setCanSubmit(formState.isValid && getValues('password') === getValues("password_repeat"))
    }, [formState])

    return <div className="recovery-password rp-s2">
        <div className="card">
            <div className="header-recovery">
                <div className="title">
                    <h2>Cambia tu contraseña</h2>
                </div>
            </div>
            <div className="content">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="p-field field field-password">
                        <label htmlFor="password">Contraseña:</label>
                        <input
                            className={errors.password ? "p-inputtext p-component p-filled p-invalid" : "p-inputtext p-component p-filled"}
                            name="password"
                            type="password"
                            ref={register({
                                required: "Contraseña obligatoria",
                                pattern: {
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%._/^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
                                    message: 'La contraseña debe estar formada mínimo por 1 mayúscula, 1 minúscula, 1 caracter especial y 1 número con una extensión mínima de 8 carateres.'
                                }
                            })}
                        />
                        {
                            errors.password && <small className="p-error p-d-block">{errors.password.message}</small>
                        }
                    </div>
                    <div className="p-field field field-password-repeat">
                        <label htmlFor="password">Repite la contraseña:</label>
                        <input
                            name="password_repeat"
                            type="password"
                            className={errors.password_repeat ? "p-inputtext p-component p-filled p-invalid" : "p-inputtext p-component p-filled"}
                            ref={register({
                                validate: value => value === password.current || "Las contraseñas no coinciden"
                            })}
                        />
                        {
                            errors.password_repeat && <small className="p-error p-d-block">{errors.password_repeat.message}</small>
                        }
                    </div>

                    <Button label="Cambiar contraseña" disabled={!canSubmit || isSubmitted} onClick={handleSubmit(onSubmit)} />
                </form>
            </div>
        </div>
    </div>
}

export default ChangePasswordForm;