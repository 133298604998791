import React, { Component } from "react";
import  DataTableHistorico  from '../components/DataTableHistorico'

class Historico extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {

    return (
      <DataTableHistorico history={this.props.history}/>

    );

  }

}

export default Historico;