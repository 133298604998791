import axios from "axios"
import authApi from '../../service/config-token-service';
import { call, put, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';

export default function* watcherSaga() {
    yield takeLatest('LOGIN_REQUEST', loginWorkerSaga);
    yield takeLatest('TOKEN_REQUEST', tokenWorkerSaga);
    yield takeLatest('API_GET_REQUEST', apiGetWorkerSaga);
    yield takeLatest('API_POST_REQUEST', apiGetWorkerSaga);
    yield takeLatest('API_PUT_REQUEST', apiPutWorkerSaga);
}

function* apiGetWorkerSaga(action) {
    try {
        const response = yield call(fetch(action.payload.request));
        const data = (p) => {
            if (p.paging === 'true') {
                return response.data.items;
            } else {
                return response.data;
            }
        };

        // despachamos la accion de tipo okAction
        yield put({
            type: action.payload.request.okAction,
            payload: {
                [action.payload.request.getKey]: data(action.payload.request)
            }
        })

    } catch (error) {
        // despachamos la accion de tipo failAction
        yield put({
            type: action.payload.request.failAction,
            payload: {
                error,
            }
        })
    }
}

function* apiPutWorkerSaga(action) {
    try {
        const response = yield call(fetch(action.payload.request));
        const data = (p) => {
            return response.request.status;
        };

        // despachamos la accion de tipo okAction
        yield put({
            type: action.payload.request.okAction,
            payload: {
                [action.payload.request.getKey]: data(action.payload.request)
            }
        })

        yield call(success())
        //LANZAR SUCCESS

    } catch (error) {

        // despachamos la accion de tipo failAction
        yield put({
            type: action.payload.request.failAction,
            payload: {
                error,
            }
        })
    }
}


/**
 * @param {type, payload} action 
 */
function* loginWorkerSaga(action) {
    try {
        
        // console.log(action)
        const response = yield call(fetch(action.payload.request));
        // console.log(response)
        // despachamos la accion de tipo okAction
        yield put({
            type: action.payload.request.okAction,
            payload: {
                userName:response.data.firstName,
                token:response.data.accessToken.token,
                refreshToken:response.data.refreshToken,
                expiresIn:response.data.accessToken.expiresIn,
                userId: response.data.userId
            }
        })

    } catch (error) {
        // despachamos la accion de tipo failAction
        yield put({
            type: action.payload.request.failAction,
            payload: {
                error:true,
            }
        })
    }
}

function* tokenWorkerSaga(action) {
    try {
        const response = yield call(tokenFetch(action.payload.request));
        // console.log(response)
        // despachamos la accion de tipo okAction
        yield put({
            type: action.payload.request.okAction,
            payload: {
                token:response.data.accessToken.token,
                expiresIn:response.data.accessToken.expiresIn,
                refreshToken:response.data.refreshToken
            }
        })

    } catch (error) {
        // despachamos la accion de tipo failAction
        yield put({
            type: action.payload.request.failAction,
            payload: {
                error:true,
            }
        })
    }
}


function fetch(request) {
    return function () {
        return axios(request);
    }
}

function tokenFetch(request){
    return function () {
       
        return authApi.request(request)
    }
}

function success() {
    store.addNotification({
        title: "¡Registro actualizado!",
        message: "El registro se ha actualizado correctamente",
        type: "success",
        insert: "top",
        container: "top-right",  
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
            dismiss: {
            duration: 5000,
            showIcon:true
        }
    });
}