import React, { useState, useEffect } from 'react';

import { Link, withRouter, Redirect } from 'react-router-dom';
import {
  registerForLocalization,
  provideLocalizationService,
} from '@progress/kendo-react-intl';
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBalanceScale,
  faBullhorn,
  faCalendar,
  faFolder,
} from '@fortawesome/free-solid-svg-icons';
import HeaderContainer from '../containers/HeaderContainer';
import LoginContainer from '../containers/LoginContainer.jsx';
import HeaderUnlogged from './Header/Unlogged';
import { Menu } from 'antd';

const items = [
  { logo: true, hiddenUnlogged: false },
  {
    name: 'procedimientos',
    iconSvg: faBalanceScale,
    selected: true,
    hiddenUnlogged: true,
    route: '/',
    match: '/',
  },
  {
    name: 'alertas',
    iconSvg: faBullhorn,
    hiddenUnlogged: true,
    route: '/alertas',
    match: 'alertas',
  },
  {
    name: 'historico',
    iconSvg: faCalendar,
    hiddenUnlogged: true,
    route: '/historico',
    match: 'historico',
  },
  {
    name: 'archivados',
    iconSvg: faFolder,
    hiddenUnlogged: true,
    route: '/archivados',
    match: 'archivados',
  },
  {
    name: 'expediente-detalle',
    route: '/expediente-detalle',
    hiddenUnlogged: true,
    hidden: true,
    match: '/',
  },
  { name: 'login', route: '/login', hiddenUnlogged: true, hidden: true },
  {
    name: 'visor-pdf',
    route: '/visor-pdf',
    hiddenUnlogged: true,
    hidden: true,
  },
  {
    name: 'recovery-password-step1',
    route: '/recovery-password-step1',
    hiddenUnlogged: true,
    hidden: true,
  },
  {
    name: 'recovery-password-step2',
    route: '/recovery-password-step2',
    hiddenUnlogged: true,
    hidden: true,
  },
];

const CustomDrawerItem = (props, loged) => {
  const { iconSvg, text, hiddenUnlogged, hidden, logo, ...others } = props;
  if (loged) {
    if (logo) {
      return <div className='logo-container'></div>;
    } else if (!hidden) {
      return (
        <DrawerItem className='menu-item' {...others}>
          <FontAwesomeIcon icon={iconSvg} />
          <span className='k-item-text'>{text}</span>
        </DrawerItem>
      );
    } else {
      return null;
    }
  }

  if (!hiddenUnlogged) {
    return <div className='logo-container'></div>;
  } else {
    return <></>;
  }
};

const DrawerRouterContainer = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [isSmallerScreen, setIsSmallerScreen] = useState(
    window.innerWidth < 768
  );
  const [selectedId, setSelectedId] = useState(
    items.findIndex((x) => x.selected === true)
  );
  const [loged, setLoged] = useState(props.loged);

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    //         this.resizeWindow()
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  const resizeWindow = () => {
    setIsSmallerScreen(window.innerWidth < 768);
  };

  const capitalize = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (!props.loged) {
    if (props.location.pathname === '/') {
      return <Redirect to='/login' />;
    } else {
      return (
        <>
          <DrawerContent>{props.children}</DrawerContent>
        </>
      );
    }
  }

  return (
    <React.Fragment>
      <HeaderContainer
        history={props.history}
        location={props.location}
        match={props.match}
      />
      <div className='row'>
        <div className={`sidebar col-xs-1`}>
          <Menu
            defaultSelectedKeys={[props.history.location.pathname]}
            inlineCollapsed={false}
            mode='inline'
            theme='light'
            selectedKeys={
              props.location.pathname.substring(
                props.location.pathname.lastIndexOf('/') + 1
              ) || '/'
            }
          >
            {items.map(
              (item) =>
                !item.hidden && (
                  <Menu.Item
                    key={item.match}
                    onClick={() => props.history.push(item.route)}
                  >
                    {capitalize(item.name)}
                  </Menu.Item>
                )
            )}
          </Menu>
        </div>
        <div className='container-fluid col-md-12 col-xs-12 container-fluid-principal'>
          <div className='row route-inner-container'>
            <div className='col-xl-12 col-md-12 col-12 route-content'>
              <DrawerContent>{props.children}</DrawerContent>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

registerForLocalization(DrawerRouterContainer);

export default withRouter(DrawerRouterContainer);
