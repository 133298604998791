import { createStore, compose, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
//import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// nuestro root reducer
import rootReducer from '../reducers/rootReducer'
import watcherSaga from '../middleware/sagas';

export function createStoreWithSageMiddleware (){

    // creamos un middleware de tipo saga
    const sagaMiddleware = createSagaMiddleware();

    // creamos nuestro store a partir del rootreducer
    // y le aplicamos el sagaMiddleware + los devtools 
    // para analizar el store y acciones en el navegador
    let store = createStore(rootReducer, 
                            compose(applyMiddleware(sagaMiddleware)
                            //,composeWithDevTools()
                                    ));

    // Poner a correr el WATCHER SAGA que hayamos creado
    // podriamos poner mas de 1 watchersaga
    sagaMiddleware.run(watcherSaga);

    return store;

}