import React, { useState, useEffect, createContext } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import DataTableDetalleExpediente from '../components/DataTableDetalleExpediente';
import ExpedienteService from '../service/expediente.service';

const expedienteService = new ExpedienteService();
export const UpdateContext = React.createContext();

const DetalleExpediente = ({
  getExpedienteById,
  putExpediente,
  expediente,
}) => {
  const history = useHistory();

  const location = useLocation();
  const [detail, setDetail] = useState(null);
  const [idExpediente, setIdExpediente] = useState(null);
  const [actualizar, setActualizar] = useState(null);

  useEffect(() => {
    let idExpediente = location.pathname.split('/')[2];

    expedienteService
      .getExpedienteById(idExpediente)
      .then((data) => {
        setDetail(data);
        setIdExpediente(idExpediente);
      })
      .catch(() => {
        // Si el procurador no tiene permiso para ver el procedimiento, se manda al listado principal.
        history.push('/');
      });
  }, [location, actualizar, history]);

  if (detail && idExpediente) {
    return (
      <div className="tablaExpedientes">
        <UpdateContext.Provider
          value={{
            updateExpediente: () => {
              setActualizar(!actualizar);
            },
            actualizar,
          }}
        >
          <DataTableDetalleExpediente
            getExpedienteById={getExpedienteById}
            putExpediente={putExpediente}
            idExpediente={idExpediente}
            detail={detail}
            history={history}
            location={location}
            expediente={expediente}
          />
        </UpdateContext.Provider>
      </div>
    );
  } else {
    return null;
  }
};

DetalleExpediente.propTypes = {};

export default DetalleExpediente;
