import moment from 'moment';
import { datesToEventMap } from './constants';

export const formatCurrency = function (amount) {
  const options = { style: 'currency', currency: 'EUR' };
  const currencyFormat = new Intl.NumberFormat('es-ES', options);
  return currencyFormat.format(amount);
};

export const formatStatus = function (state) {
  var color = '';
  switch (state) {
    case 1:
      color = 'blue';
      break;
    case 2:
      color = 'green';
      break;
    case 3:
      color = 'red';
      break;
    case 4:
      color = 'yellow';
      break;
    case 5:
      color = 'grey';
      break;
    default:
      color = '';
      break;
  }

  return color;
};

export const formatDate = function (dateTime, format = 0) {
  //2020-06-04T16:31:53.290 Recibe una fecha de este formato
  //Formato 0: 2020-06-04 16:31:53
  //Formato 1: 2020-06-04
  //Formato 2: 16:31:53
  var f = '';
  var cadena = dateTime.split('T');
  var fecha = cadena[0].split('-');
  var hora = cadena[1].split('.')[0];
  switch (format) {
    case 0:
      f = fecha[2] + '/' + fecha[1] + '/' + fecha[0] + ' ' + hora;
      break;
    case 1:
      f = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
      break;
    case 2:
      f = hora;
      break;
    default:
      f = '';
  }
  return f;
};

export const datesToEvent = (
  alerta,
  demandado,
  procedimiento,
  juzgado,
  description,
  demandados,
  fechaVista
) => {
  // if (!alerta?.valorPropiedades) return false;

  const res = [];

  if (alerta) {
    alerta.forEach((x) => {
      if (datesToEventMap.includes(x?.nombre.toLowerCase())) {
        res.push({
          title: `${
            demandados ? `${demandados} - ` : demandado ? `${demandado} - ` : ''
          }${procedimiento || ' '}${
            x?.nombre.toLowerCase() === 'fecha vista' ? ' (V.O.)' : ' (A.P.)'
          }`,
          description: description,
          startTime: moment(fechaVista, 'DD/MM/YYYY').toDate(),
          location: juzgado,
          attendees: ['Notificaciones<notificaciones@gedesco.es>'],
        });
      }
    });
  }
  return res;
};

export const calendarES = {
  firstDayOfWeek: 1,
  dayNames: [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  monthNamesShort: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ],
  today: 'Hoy',
  clear: 'Limpiar',
};
