import axios from 'axios';
import { store } from 'react-notifications-component';
import * as Sentry from '@sentry/react';
import { logout, resetToken } from '../store/actions/actions';

export default {
  setupInterceptors: (stor) => {
    let expiracy = new Date(sessionStorage.getItem('expire'));
    axios.interceptors.request.use(
      function (config) {
        let now = new Date();
        let token = sessionStorage.getItem('token');

        now.setMinutes(now.getMinutes() + 2);

        if (token && expiracy != null && now.getTime() > expiracy.getTime()) {
          expiracy = null;
          let refresh = sessionStorage.getItem('r');
          stor.dispatch(resetToken(token, refresh));
        }

        config.baseURL = process.env.REACT_APP_API_URL;
        //config.responseType = 'json';
        config.headers.Authorization = `Bearer ` + token;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        if (response.status === 200) {
          return response;
        }
      },
      function (error) {
        if (error?.response?.status === 401) {
          stor.dispatch(logout());
        } else if (process.env.REACT_APP_API_ENV === 'PRODUCTION') {
          Sentry.captureException(error);
        }
        return Promise.reject(error);
      }
    );
  },
};
