import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DataTableExpedientes from '../components/DataTableExpedientes';
import { Toast } from 'primereact/toast';

const Expedientes = ({
  fetching,
  onLoad,
  putExpediente,
  history,
  expedientes,
}) => {
  const toastRef = useRef();
  const location = useLocation();
  const [showArchivados, setShowArchivados] = useState(false);
  useEffect(() => {
    if (location?.pathname === '/archivados') {
      setShowArchivados(true);
    } else {
      setShowArchivados(false);
    }
  }, [location]);
  return (
    <div className="tablaExpedientes">
      <Toast ref={toastRef} />
      <DataTableExpedientes
        fetching={fetching}
        expedientes={expedientes}
        onLoad={onLoad}
        putExpediente={putExpediente}
        history={history}
        showArchivados={showArchivados}
      />
    </div>
  );
};

export default Expedientes;
