import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import Moment from 'react-moment';
import { Button } from 'primereact/button';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import AlertasMensajesService from '../service/alerta.service';
import DetalleExpedienteService from '../service/detalleExpedienteService';
import DocumentosService from '../service/documentos.service';
import ExpedienteService from '../service/expediente.service';
import 'moment/locale/es';

const { RangePicker } = DatePicker;
const status = [
  { name: 'Aprobado', code: 'Aprobado' },
  { name: 'Rechazado', code: 'Rechazado' },
  { name: 'Revision', code: 'Revision' },
  { name: 'Todos', code: 'Todos' },
];

const DataTableHistorico = () => {
  const history = useHistory();
  const [docNuevos, setDocNuevos] = useState([]);
  const [rango, setRango] = useState([]);
  const [docFiltrados, setDocFiltrados] = useState(null);
  const [seleccion, setSeleccion] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState({
    name: 'Todos',
    code: 'Todos',
  });

  const alertasMensajesService = new AlertasMensajesService();
  const detalleExpedienteService = new DetalleExpedienteService();
  const documentosService = new DocumentosService();
  const expedienteService = new ExpedienteService();

  useEffect(() => {
    documentosService.getDocumentosByFecha().then((data) => {
      let documentosFiltrados = data.items.filter(
        (x) => x.discriminatorValue === 'Documento'
      );
      setDocNuevos(documentosFiltrados);
    });
  }, []);

  const onRowSelect = (event) => {
    expedienteService
      .getExpedienteById(event.data.expedienteId)
      .then((data) => {
        event.data = data;
        history.push({
          pathname: '/expediente-detalle/' + event.data.id,
          state: { detail: event.data },
        });
      });
  };

  const fechaTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex' }}>
        <div className="col-name">
          <span className="p-column-title">Fecha Subida</span>
        </div>
        <div>
          {rowData.creationTime ? (
            <Moment format="DD/MM/YYYY">{rowData.creationTime}</Moment>
          ) : (
            'No hay fecha de acción'
          )}
        </div>
      </div>
    );
  };

  const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/pdf;base64,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const descargarDocs = (rowData) => {
    var documentosService = new DocumentosService();   
    documentosService
      .getDocumentoJustificante(rowData.id)
      .then((data) => {       
        download('Justificante '+rowData.tipoDocumentoNombre, data);
      });
  };

  const getJustificante = (rowData) => {

    return (
      <Button
        onClick={(e) => {
          descargarDocs(rowData);
          e.stopPropagation();         
        }}
        icon="pi pi-eye"
        className="p-button p-component p-button-icon-only"
      />
    );
  }

  const getStatus = (rowData) => {
    if (rowData.estadoNombre === 'Aprobado')
      return (
        <label className="status-label status-label-green">
          {rowData.estadoNombre}
        </label>
      );

    if (rowData.estadoNombre === 'Rechazado')
      return (
        <div>
          <label className="status-label status-label-red">
            {rowData.estadoNombre}
          </label>
          <p>{rowData.motivoEstado}</p>
        </div>
      );

    if (rowData.estadoNombre === 'Revision')
      return (
        <label className="status-label status-label-yellow">
          {rowData.estadoNombre}
        </label>
      );
  };

  const standardTextRender = (rowData, fieldData) => {
    return (
      <div className="row">
        <div className="col-md-2 ocultar-movil">
          <span className="p-column-title">{fieldData.header}</span>
        </div>
        <div className="col-md-10">
          {rowData[fieldData.field] ? rowData[fieldData.field] : ''}
        </div>
      </div>
    );
  };

  const getDocumentosFecha = (rango, fecha) => {
    documentosService.getDocumentosByFecha(rango, fecha).then((data) => {
      let documentosFiltrados = data.items.filter(
        (x) => x.discriminatorValue === 'Documento'
      );
      setDocNuevos(documentosFiltrados);
      setDocFiltrados(null);
      setSelectedStatus({ name: 'Todos', code: 'Todos' });
    });
  };

  const onStatusChange = (e) => {
    let filtrados = docNuevos.filter((x) => x.estadoNombre === e.value.name);
    setDocNuevos(docNuevos);
    setSelectedStatus(e.value);
    setDocFiltrados(filtrados);

    if (e.value.name === 'Todos') setDocFiltrados(null);
  };

  const selectedStatusTemplate = (option) => {
    if (option) {
      if (option.name === 'Aprobado')
        return (
          <label className="status-label status-label-green">
            {option.name}
          </label>
        );

      if (option.name === 'Rechazado')
        return (
          <label className="status-label status-label-red">{option.name}</label>
        );

      if (option.name === 'Revision')
        return (
          <label className="status-label status-label-yellow">
            {option.name}
          </label>
        );
      if (option.name === 'Todos')
        return <label className="status-label">Todos</label>;
    } else {
      return <label className="status-label">Todos</label>;
    }
  };
  const SinAlertas = () => {
    return (
      <div
        id="sin-alertas"
        className={'datatable-templating datatable-responsive '}
      >
        <h2 className="expedientes-title">Sin documentos</h2>
      </div>
    );
  };
  const ComDocNuevos = () => {
    return (
      <div
        id="tabla-doc-nuevos"
        className={'datatable-templating datatable-responsive'}
      >
        <h2 className="expedientes-title">Nuevos Documentos</h2>
        <DataTable
          value={docFiltrados || docNuevos}
          lazy
          emptyMessage="No hay documentos"
          onRowSelect={onRowSelect}
          selectionMode="single"
          dataKey="id"
          className="p-datatable-striped p-datatable-responsive"
        >
          <Column field="id" header="ID" style={{ display: 'none' }}></Column>
          <Column
            field="tipoDocumentoNombre"
            header="Documento"
            body={standardTextRender}
            // bodyStyle={{whiteSpace: 'nowrap'}}
          ></Column>
          <Column
            field="expedienteProcedimiento"
            header="Procedimiento"
            body={standardTextRender}
          ></Column>
          <Column
            field="expedienteReferencia"
            header="Referencia"
            body={standardTextRender}
          ></Column>
          <Column
            field="creationTime"
            header="Fecha Subida"
            body={fechaTemplate}
          ></Column>
          <Column field="estado" header="Estado" body={getStatus}></Column>
          <Column field="id" header="Justificante" body={getJustificante}></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div id="historico">
      <div className="botonera">
        <Button
          label="Hoy"
          className={seleccion === 1 ? 'p-button-primary' : 'p-button-outlined'}
          onClick={() => {
            setSeleccion(1);
            setRango(null);
            getDocumentosFecha(1);
          }}
        />
        <Button
          label="Semana"
          className={seleccion === 2 ? 'p-button-primary' : 'p-button-outlined'}
          onClick={() => {
            setSeleccion(2);
            setRango(null);
            getDocumentosFecha(2);
          }}
        />

        <label htmlFor="range" style={{ marginLeft: '30px' }}>
          Selecciona fechas{' '}
        </label>
        <RangePicker
          size={'small'}
          locale={locale}
          value={rango}
          onChange={(e) => {
            getDocumentosFecha(3, e);
            setSeleccion(3);
            setRango(e);
          }}
          style={{ height: '43px', marginBottom: '10px', marginLeft: '10px' }}
        />
        <label
          htmlFor="range"
          style={{ marginLeft: '40px', marginRight: '20px' }}
        >
          Estado
        </label>
        <Dropdown
          value={selectedStatus}
          options={status}
          optionLabel="name"
          itemTemplate={selectedStatusTemplate}
          valueTemplate={selectedStatusTemplate}
          onChange={onStatusChange}
        />
      </div>

      {docNuevos.length > 0 ? <ComDocNuevos /> : <SinAlertas />}
    </div>
  );
};
export default DataTableHistorico;
