export const idPropiedad = {
  FECHA_PLAZO: 2,
  FECHA_NOTIFICACION: 26,
  FECHA_VISTA: 12,
};

export const idsPropiedadesNoRequeridas = [
  idPropiedad.FECHA_PLAZO,
  idPropiedad.FECHA_NOTIFICACION,
];

export const datesToEventMap = ['fecha vista', 'fecha audiencia'];
