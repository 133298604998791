import React from 'react';
import { Button } from 'primereact/button';

import DetalleExpedienteService from 'service/detalleExpedienteService';
import DocumentosService from 'service/documentos.service';

const GetStatus = ({ rowData, setFormState, setDetallesDoc }) => {
  const actualizarDoc = (e) => {
    setFormState((fs) => ({ ...fs, replace: e }));
  };
  //rowData = rowData.rowData;
  if (rowData.estadoNombre === 'Aprobado')
    return (
      <label className="status-label status-label-green">
        {rowData.estadoNombre}
      </label>
    );

  if (rowData.estadoNombre === 'Rechazado')
    return (
      <div className="row">
        <div className="col-sm-6">
          <label className="status-label status-label-red">
            {rowData.estadoNombre}{' '}
          </label>
          <br></br>
          <span className="status-class-component">{rowData.motivoEstado}</span>
        </div>
        <div className="col-sm-6" style={{ whiteSpace: 'nowrap' }}>
          <Button
            label={'Reemplazar'}
            onClick={(e) => {
              actualizarDoc(rowData);
            }}
          ></Button>
          {rowData.motivoEstado === 'Este documento no es de este tipo' && (
            <Button
              onClick={(e) => {
                let documentosService = new DocumentosService();
                let detalleExpedienteService = new DetalleExpedienteService();

                documentosService
                  .deleteDocumentoById(rowData.id)
                  .then((data) => {
                    detalleExpedienteService
                      .getAllDocumentosSubidos(
                        rowData.expedienteId,
                        rowData.tipoDocumentoId
                      )
                      .then((datos) => {
                        setDetallesDoc(datos ? [datos.data.items] : []);
                      });
                  });
              }}
              icon="pi pi-trash"
              className="p-button p-component p-button-icon-only"
              style={{ margin: '0px 15px' }}
            />
          )}
        </div>

        <span></span>
      </div>
    );

  if (rowData.estadoNombre === 'Revision')
    return (
      <label className="status-label status-label-yellow">
        {rowData.estadoNombre}
      </label>
    );

  return '';
};

export default GetStatus;
