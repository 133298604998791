//reducer snippet

const initialState = {
    fetching: false,
    token: sessionStorage.getItem("token"),
    loged: sessionStorage.getItem("token")?'true':null,
    error: null,
    userName: sessionStorage.getItem("userName"),
    refreshToken:null
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // ! LOGOUT
        case 'LOGOUT':
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("expire");
            sessionStorage.removeItem('userId');
            return {
                ...state,
                token: null,
                loged: false,  
                id: null,
                name: null,
                lastname: null             
            };
        // ! LOGIN
        case 'LOGIN_REQUEST':
            return {
                ...state,
                fetching: true,
                error: null,
                token: null,
                loged: false                
            };  
        case 'LOGIN_SUCCESS':
            // if (action.payload.remember === true) {
                // console.log(action.payload.expiresIn)
                sessionStorage.setItem('token', action.payload.token);
                sessionStorage.setItem('userName', action.payload.userName);
                sessionStorage.setItem('userId', action.payload.userId);
                sessionStorage.setItem('r',action.payload.refreshToken);
                let now = new Date();
                now.setSeconds(action.payload.expiresIn)
                sessionStorage.setItem('expire', now)
            // }
           
            return {
                ...state,
                fetching: false,
                error: false,
                loged: true,
                refreshToken: action.payload.refreshToken,
                token: action.payload.token,
                userName: action.payload.userName,
                userId: action.payload.userId
            };
        case 'LOGIN_FAILURE':
            return {
                ...state,
                fetching:false,
                loged: false,
                token: null,
                refreshToken:null,
                error: action.payload.error
            };
        case 'TOKEN_SUCCESS':
                sessionStorage.setItem('token', action.payload.token);
                sessionStorage.setItem('r',action.payload.refreshToken);
                let renow = new Date();
                renow.setSeconds(action.payload.expiresIn)
                sessionStorage.setItem('expire', renow)
            return {
                ...state,
                fetching: false,
                error: null,
                loged: true,
                refreshToken: action.payload.refreshToken,
                token: action.payload.token,
            };
        case '':
            return {
                ...state,
                fetching:false,
                loged: false,
                token: null,
                refreshToken:null,
                error: action.payload.error
            };
        default:
            return state
    }
}

export default userReducer;