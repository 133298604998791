import axiosInstance from '../utils/config/axios.config';

/**
 * Servicio dedicado a encontrar la Razón Social, utilizado en los formularios de factura y pagaré
 */
export default class RecoveryPasswordService {
  async sendResetPasswordEmail(data) {
    return await axiosInstance.post(
      `/api/Authentication/Send-reset-password-email`,
      {
        email: data.email,
        passwordResetUrl: `${window.location.origin}/recovery-password-step2`,
      },
      {
        baseURL: process.env.REACT_APP_API_USER_URL,
      }
    );
  }

  async resetPassword(data, token, userId) {
    return await axiosInstance.post(
      `/api/Authentication/Reset-password`,
      {
        password: data.password,
        token: token,
        userId: userId,
      },
      {
        baseURL: process.env.REACT_APP_API_USER_URL,
      }
    );
  }
}
