import { connect } from 'react-redux'
import {Header} from '../components/Header2'

import { logout, resetToken } from '../store/actions/actions';

const mapStateToProps = (state) => {
    return {
        loged: state.userState.loged,
        apiState:state.apiState,
        refreshToken:state.userState.refreshToken?state.userState.refreshToken:null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequestLogout: () => {
            dispatch(logout())
        },
        onRequestResetToken: (token,refreshToken) => {
            dispatch(resetToken(token,refreshToken))
        }
    }
}

let HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer;