import React from 'react';
import { useLocation } from 'react-router-dom';

const useLocationChange = (action) => {
  const location = useLocation();
  React.useEffect(() => {
    action(location);
  }, [location]);
};

export default useLocationChange;
