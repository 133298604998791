import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { DataTable } from 'primereact/datatable';
import ICalendarLink from 'react-icalendar-link';
import ExpedienteService from '../../service/expediente.service';
import { Column } from 'primereact/column';
import { loadMessages } from '@progress/kendo-react-intl';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { UpdateContext } from 'pages/DetalleExpediente';
import esMessages from '../form/es.json';
import Moment from 'react-moment';
import PDFViewer from '../PDFViewer';
import {
  GetBotonPdf,
  RowExpansionTemplate,
  GetStatus,
  FormTemplate,
} from './components';
import DetalleExpedienteService from '../../service/detalleExpedienteService';
import DocumentosService from '../../service/documentos.service';
import 'moment/locale/es';
import { idPropiedad, idsPropiedadesNoRequeridas } from 'utils/constants';
import { datesToEvent } from 'utils/utils';
import { message, notification } from 'antd';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
loadMessages(esMessages, 'es-ES');

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'es';
// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD/MM/YYYY';
// Set the output timezone for local for every instance.
Moment.globalLocal = true;

const detalleExpedienteService = new DetalleExpedienteService();
const documentosService = new DocumentosService();
const expedienteService = new ExpedienteService();

const FormDetalleExpediente = ({
  expedienteId,
  tipoDocumentoId,
  titulo,
  detalleCampos,
  subidoProcurador,
}) => {
  const [formState, setFormState] = useState({
    dateValue: null,
    textAreaValue: '',
    tituloValue: '',
    replace: null,
    selectValue: null,
    camposObligatoriosIns: 0,
    camposObligatoriosNum: 0,
  });
  const [detallesDoc, setDetallesDoc] = useState('');
  const [detallesDocLegacy, setDetallesDocLegacy] = useState('');
  const [docLeida, setDocLeida] = useState('');
  const [ficheros, setFicheros] = useState([]);
  const [camposArray, setCamposArray] = useState([]);
  const [base64, setBase64] = useState(false);
  const [expandedRows, setExpandedRows] = useState();
  const [documentoIdLegacy, setDocumentoLegacy] = useState(false);
  const [details, setDetails] = useState();
  const [zipBase64, setZipBase64] = useState();

  const filepondRef = useRef();
  const { updateExpediente } = useContext(UpdateContext);

  const clearForm = useCallback((clearAll = false) => {
    setFormState((fs) => ({
      ...(!clearAll ? fs : {}),
      dateValue: null,
      textAreaValue: '',
      tituloValue: '',
      replace: null,
      selectValue: null,
      camposObligatoriosIns: 0,
      camposObligatoriosNum: clearAll ? 0 : fs.camposObligatoriosNum,
    }));

    if (filepondRef?.current) filepondRef.current.removeFiles();
  }, []);

  useEffect(() => {
    expedienteService
      .getExpedienteById(expedienteId)
      .then((data) => {
        setDetails(data);
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  }, []);

  const fechaVista = camposArray.find(
    (objeto) => objeto.PropiedadId === idPropiedad.FECHA_VISTA
  )?.Valor;

  useEffect(() => {
    if (detalleCampos) {
      const camposObligatorios = detalleCampos.filter(
        (campo) =>
          !idsPropiedadesNoRequeridas.includes(campo.id) &&
          campo.asignablePor !== 1
      );
      var totalObligatorios = camposObligatorios.length;

      setFormState((fs) => ({
        ...fs,
        camposObligatoriosNum: totalObligatorios,
      }));
      setCamposArray((campos) => {
        campos.forEach((campo) =>
          setFormState((fs) => {
            return { ...fs, [campo.PropiedadId]: '' };
          })
        );
        return [];
      });
      setFicheros([]);
      setDocumentoLegacy(false);
    }
  }, [detalleCampos]);

  useEffect(() => {
    if (tipoDocumentoId && expedienteId) {
      detalleExpedienteService
        .getAllDocumentosSubidos(expedienteId, tipoDocumentoId)
        .then((data) => {
          let abogado = data.data.items.filter(
            (x) => x.discriminatorValue === 'DocumentoAbogado'
          );
          let procurador = data.data.items.filter(
            (x) => x.discriminatorValue === 'Documento'
          );

          if (!subidoProcurador && tipoDocumentoId === 1) {
            abogado.unshift({
              descripcion: 'Índice',
              numero: '',
              tipoDocumentoId,
              expedienteId,
            });
          }
          setDetallesDoc([procurador]);
          setDetallesDocLegacy(abogado);
        });

      documentosService
        .getDocumentoAbogadoExistsNoLeidosByExpediente(expedienteId)
        .then((data) => {
          setDocLeida(!data);
        });
      clearForm();
    }
  }, [tipoDocumentoId, expedienteId, subidoProcurador, clearForm]);

  const getStatus = (rowData) => {
    return (
      <GetStatus
        rowData={rowData}
        setFormState={setFormState}
        setDetallesDoc={setDetallesDoc}
      />
    );
  };

  const getBotonPdf = (rowData) => {
    return <GetBotonPdf rowData={rowData} setBase64={setBase64} />;
  };

  const getNumero = (rowData) => {
    return rowData.numero;
  };

  const getDocumentoNombre = (rowData) => {
    return rowData.descripcion;
  };

  const getExpander = () => {
    return <button>Ver Reporte</button>;
  };

  const events = datesToEvent(
    detalleCampos,
    details?.demandado,
    details?.procedimiento,
    details?.juzgado,
    titulo,
    details?.demandados,
    fechaVista
  );

  const handleUpload = () => {
    const datos = {
      expedienteId,
      tipoDocumentoId,
      ValorPropiedades: camposArray,
    };

    if (formState.replace) {
      detalleExpedienteService
        .updateDoc(formState.replace, ficheros[0].file)
        .then(() => {
          camposArray.forEach((element) => {
            detalleExpedienteService.putEditarCampos(element).then((res) => {
              //setB64(res);
            });
          });
          detalleExpedienteService
            .getAllDocumentosSubidos(expedienteId, tipoDocumentoId)
            .then((data) => {
              let abogado = data.data.items.filter(
                (x) => x.discriminatorValue === 'DocumentoAbogado'
              );
              let procurador = data.data.items.filter(
                (x) => x.discriminatorValue === 'Documento'
              );
              clearForm(true);
              setDetallesDoc([procurador]);
              setDetallesDocLegacy(abogado);
              setFicheros([]);
              updateExpediente();
            });
        });
    } else {
      detalleExpedienteService
        .guardarForm(datos, ficheros)
        .then((res) => {
          if (res) {
            notification.success({
              description: 'Se ha subido el documento correctamente',
            });
          }
          detalleExpedienteService
            .getAllDocumentosSubidos(expedienteId, tipoDocumentoId)
            .then((data) => {
              let abogado = data.data.items.filter(
                (x) => x.discriminatorValue === 'DocumentoAbogado'
              );
              let procurador = data.data.items.filter(
                (x) => x.discriminatorValue === 'Documento'
              );
              clearForm(true);
              setDetallesDoc([procurador]);
              setDetallesDocLegacy(abogado);
              setFicheros([]);
              updateExpediente();
            });
        })
        .catch(() =>
          notification.error({
            message: 'ERROR',
            description:
              'Ha ocurrido un error al subir el documento, intentelo otra vez',
          })
        );

      if (filepondRef?.current) filepondRef.current.removeFiles();
    }
  };

  const getBotonPdfLegacy = (rowData) => {
    return (
      <Button
        onClick={(e) => {
          var documentosService = new DocumentosService();

          documentosService.getFileB64Legacy(rowData).then((data) => {
            setBase64(data);
          });
        }}
        icon='pi pi-eye'
        className='p-button p-component p-button-icon-only'
      />
    );
  };

  const formatDateUTC = (data) => {
    return <Moment utc>{data}</Moment>;
  };

  const formatDate = (date) => {
    return date && <Moment>{date}</Moment>;
  };

  const formatDateTime = (date) => {
    return date && <Moment format='DD/MM/YYYY HH:mm'>{date}</Moment>;
  };

  const showTitulo = (rowData) => {
    return rowData.valorPropiedades?.find((x) => x.propiedadId === 3)
      ? rowData.valorPropiedades.find((x) => x.propiedadId === 3).valor
      : '';
  };

  const onRowExpand = () => {};

  const onRowCollapse = () => {};

  const resetB64 = () => {
    setBase64(null);
  };

  const rowExpansionTemplate = (data) => {
    return <RowExpansionTemplate data={data} />;
  };

  const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/zip;base64,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const descargarDocs = () => {
    var documentosService = new DocumentosService();

    documentosService
      .getZipB64Documento(expedienteId, tipoDocumentoId)
      .then((data) => {
        setZipBase64('data:application/zip;base64,' + data);
        download('Documentos.zip', data);
      });
  };

  const marcarDocPresentada = () => {
    var documentosService = new DocumentosService();

    documentosService
      .putDocumentoAbogadoLeidoByIdExpediente(expedienteId, tipoDocumentoId)
      .then(() => {
        setDocLeida(true);
      });
  };

  let modalPDF = '';
  if (base64) {
    modalPDF = (
      <div style={{ width: '100%', margin: '0px 10px 0px 10px' }}>
        <PDFViewer modalIsOpen={true} base64={base64} resetB64={resetB64} />
      </div>
    );
  }
  const AcordeonTemplate = () => {
    return detallesDoc[0].valorPropiedades.map((tipo, index) => {
      const valorPropiedad = detallesDoc[0].valorPropiedades[index];
      if (
        valorPropiedad.propiedad.id !== idPropiedad.FECHA_PLAZO &&
        valorPropiedad.propiedad.id !== 3
      ) {
        return (
          <Accordion key={index} activeIndex={0}>
            <AccordionTab header={valorPropiedad.propiedad.nombre}>
              {valorPropiedad.propiedad.tipo === 'System.Boolean'
                ? valorPropiedad.valor === 'true'
                  ? 'Sí'
                  : tipo.valor === 'false'
                  ? 'No'
                  : 'No definido'
                : valorPropiedad.valor}
            </AccordionTab>
          </Accordion>
        );
      }
      return false;
    });
  };

  if (!subidoProcurador) {
    return (
      <div id='form-detalle-expediente'>
        {modalPDF}
        <DataTable
          emptyMessage={'No hay documentos'}
          value={detallesDocLegacy ? detallesDocLegacy : []}
          className='datatable-responsive'
        >
          <Column header='Número' body={getNumero}></Column>
          <Column header='Documento' body={getDocumentoNombre}></Column>
          <Column
            className='text-center'
            field='extension'
            header='Ver Doc.'
            body={getBotonPdf}
          ></Column>
          <Column
            style={{ width: '25%' }}
            field='creationTime'
            header='Fecha Plazo'
            body={(rowData) => formatDate(rowData.fechaPlazo)}
          ></Column>
        </DataTable>
        {detallesDocLegacy.length > 0 ? (
          <div className='button-descargar-zip'>
            <Button onClick={descargarDocs}>Descargar ZIP</Button>
          </div>
        ) : (
          ''
        )}
        {detallesDocLegacy.length > 0 && !docLeida ? (
          <div className='button-documentacion-presentada'>
            <Button onClick={marcarDocPresentada}>
              Documentación presentada
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  } else {
    return (
      <div id='form-detalle-expediente'>
        {modalPDF}
        <span className='cabecera-form-subdetalle'>
          {detallesDocLegacy.length > 0 ? 'Documentación para presentar:' : ''}
        </span>
        {detallesDocLegacy.length > 0 && (
          <DataTable
            emptyMessage={'No hay documentos'}
            value={detallesDocLegacy ? detallesDocLegacy : []}
            className='datatable-responsive'
          >
            <Column header='Número' body={getNumero}></Column>
            <Column header='Documento' body={getDocumentoNombre}></Column>
            <Column
              className='text-center'
              field='extension'
              header='Ver Doc.'
              body={(e) => getBotonPdf(e)}
            ></Column>
            <Column
              style={{ width: '25%' }}
              field='creationTime'
              header='Fecha Plazo'
              body={(rowData) => formatDate(rowData.fechaPlazo)}
            ></Column>
          </DataTable>
        )}
        {detallesDocLegacy.length > 0 ? (
          <div className='button-descargar-zip'>
            <Button onClick={descargarDocs}>Descargar ZIP</Button>
          </div>
        ) : (
          ''
        )}
        {detallesDocLegacy.length > 0 && !docLeida ? (
          <div className='button-documentacion-presentada'>
            <Button onClick={marcarDocPresentada}>
              Documentación presentada
            </Button>
          </div>
        ) : (
          ''
        )}
        <br></br> <br></br> <br></br>
        <br></br>
        <span className='cabecera-form-subdetalle'>
          Subida de documentación:
        </span>
        <br></br> <br></br>
        {detallesDoc.length > 0 && (
          <DataTable
            value={detallesDoc[0]}
            className='mt20 tablaDetalle datatable-responsive'
            emptyMessage={'No hay documentos'}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey='id'
          >
            <Column expander style={{ width: '10%' }} />
            <Column
              style={{ width: '10%' }}
              className='text-center'
              field='extension'
              header='Ver Doc.'
              body={(e) => getBotonPdf(e)}
            ></Column>
            {tipoDocumentoId === 129 ? (
              <Column
                field='valorPropiedad'
                header='Nombre documento'
                body={showTitulo}
              ></Column>
            ) : null}
            <Column
              style={{ width: '25%' }}
              header='Estado'
              body={(e) => getStatus(e)}
            ></Column>
            <Column
              style={{ width: '25%' }}
              field='creationTime'
              header='Fecha Subida'
              body={(rowData) => formatDateTime(rowData.creationTime)}
            ></Column>
          </DataTable>
        )}
        {detallesDoc[0]?.valorPropiedades && <AcordeonTemplate />}
        <br></br>
        <br></br>
        <span className='cabecera-form-subdetalle'>
          {formState.replace ? 'Reemplazar documento' : ''}
        </span>
        <br></br>
        <br></br>
        {detalleCampos ? (
          <FormTemplate
            detalleCampos={detalleCampos}
            camposArray={camposArray}
            setCamposArray={setCamposArray}
            formState={formState}
            setFormState={setFormState}
          />
        ) : (
          ''
        )}
        <h2>Adjuntar Documento</h2>
        <div className='formulario-subida-detalle-expediente'>
          <FilePond
            //files={ficheros}
            allowMultiple
            name='files'
            labelIdle='Arrastra el documento o <span className="filepond--label-action">haz click aquí</span>'
            credits={false}
            styleButtonRemoveItemPosition={'right'}
            ref={filepondRef}
            onaddfile={(error, file) => {
              let arFicheros = [...ficheros];
              arFicheros.push(file);
              setFicheros(arFicheros);
            }}
            onremovefile={(error, file) => {
              let arFicheros = ficheros.filter(
                (fichero) => fichero.id !== file.id
              );
              setFicheros(arFicheros);
            }}
          />
          {formState.camposObligatoriosNum ===
            formState.camposObligatoriosIns && ficheros.length ? (
            events ? (
              <ICalendarLink event={events.length ? events[0] : undefined}>
                <Button
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  Subir
                </Button>
              </ICalendarLink>
            ) : (
              <Button
                onClick={() => {
                  handleUpload();
                }}
              >
                Subir
              </Button>
            )
          ) : (
            'Complete los campos para poder adjuntar'
          )}
        </div>
      </div>
    );
  }
};

export default FormDetalleExpediente;
