import React, { useState, useEffect, useCallback, useContext } from 'react';
import DetalleExpedienteService from '../service/detalleExpedienteService';
import ExpedienteService from '../service/expediente.service';
import { ListBox } from 'primereact/listbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { UpdateContext } from 'pages/DetalleExpediente';

const detalleExpedienteService = new DetalleExpedienteService();
const ExpedienteServiceId = new ExpedienteService();

const TipoDocTarjetas = ({
  clickDocSeleccionado,
  clickDocSeleccionadoLegacy,
  expedienteId,
}) => {
  const { actualizar } = useContext(UpdateContext);

  const [categorias, setCategorias] = useState([]);
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [tiposDocumentoLimite, setTiposDocumentoLimite] = useState([]);
  const [todos, setTodos] = useState([]);
  const [demandados, setDemandados] = useState();
  const [selectedExpediente, setSelectedExpediente] = useState();
  const [tiposAbogadoPendiente, setTiposAbogadoPendiente] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const [propertyData, setPropertyData] = useState();
  const [index, setIndex] = useState();
  const [eventIndex, setEventIndex] = useState();
  const [diligencias, setDiligencias] = useState();

  const documentoClick = useCallback(
    (lineaListBox) => {
      clickDocSeleccionado(lineaListBox);
    },
    [clickDocSeleccionado]
  );
  const documentoClickLegacy = (lineaListBox) => {
    clickDocSeleccionadoLegacy(lineaListBox);
  };
  const setExpedienteLeido = useCallback(() => {
    detalleExpedienteService.setExpedienteLeido(expedienteId);
  }, [expedienteId]);

  useEffect(() => {
    ExpedienteServiceId.getExpedienteById(expedienteId)
      .then((data) => {
        setDemandados(data?.bienesDemandados);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (eventIndex) {
      detalleExpedienteService
        .getDiligenciasByBienId(expedienteId, propertyData.id)
        .then((data) => {
          setDiligencias(data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setDiligencias();
    }
  }, [eventIndex]);

  useEffect(() => {
    detalleExpedienteService.getCategoriasTipoDocumento().then((data) => {
      setCategorias(data.items);
      detalleExpedienteService
        .getTipoDocumentoByExpedienteId(expedienteId)
        .then((res) => {
          let documentTypeLimite = [];
          let documentType = [];
          let todosDocumentos = [];
          if (res?.length) {
            res.forEach((tipo) => {
              if (tipo.tipoDocumento['categoriaNombre'] === null) {
                tipo['fechaLimite']
                  ? documentTypeLimite.push(tipo)
                  : documentType.push(tipo);
              }
              todosDocumentos.push(tipo);
              return tipo;
            });
          }
          setTiposDocumento(documentType);
          setTiposDocumentoLimite(documentTypeLimite);
          setTodos(todosDocumentos);
          setSelectedExpediente((sExpediente) => {
            if (window.innerWidth > 768 && !sExpediente) {
              let clickedElement =
                documentTypeLimite.length > 0
                  ? documentTypeLimite[0]
                  : documentType[0];
              documentoClick(clickedElement);
              return clickedElement;
            }
            return sExpediente;
          });
        });
    });

    detalleExpedienteService
      .getDocumentoAbogadoNoLeidoByExpedienteId(expedienteId)
      .then((data) => {
        let datos = data.items;

        datos = datos.map((element) => {
          let tipoDocumento = {
            nombre: element.tipoDocumentoNombre,
            id: element.tipoDocumentoId,
            subidoPorProcurador: false,
          };
          element = { ...element, tipoDocumento };
          return element;
        });
        // Desde el back nos llega duplicado si han subido desde abogados varios docs a un mismo Expediente y Tipo Documento
        // COn esto eliminamos duplicados
        let unique = datos.reduce((accumulator, current) => {
          if (checkIfAlreadyExist(current)) {
            return accumulator;
          } else {
            return [...accumulator, current];
          }
          function checkIfAlreadyExist(currentVal) {
            return accumulator.some((item) => {
              return (
                item.expedienteId === currentVal.expedienteId &&
                item.tipoDocumentoId === currentVal.tipoDocumentoId
              );
            });
          }
        }, []);

        setTiposAbogadoPendiente(unique);
      });

    setExpedienteLeido();
  }, [expedienteId, documentoClick, setExpedienteLeido, actualizar]);

  const statusTemplate = (option) => {
    var titulo =
      option.tipoDocumento != null
        ? option.tipoDocumento.nombre
        : option.descripcion;
    var estado =
      !option.tipoDocumento.subidoPorProcurador && option.estadoUltimoDocumento
        ? 'doc-abogado'
        : '';

    return (
      <div className="">
        <span
          className={
            'documentoEstado ' +
            estado +
            ' documentoEstado-' +
            option.estadoUltimoDocumento
          }
        >
          <span style={{ color: '#007bff' }}>
            {option.documentosEnRevision > 0
              ? option.documentosEnRevision
              : ' '}
          </span>
        </span>
        {titulo}
      </div>
    );
  };

  const DocFechaLimite = () => {
    return (
      <div className="">
        <h4>Documentos con acciones a realizar</h4>
        <ListBox
          options={tiposDocumentoLimite}
          value={selectedExpediente}
          optionLabel="tipoDocumento.nombre"
          style={{ width: '100%' }}
          itemTemplate={statusTemplate}
          onChange={(e) => {
            setSelectedExpediente(e.value ? e.value : selectedExpediente);
            if (e.value) {
              documentoClick(e.value);
            }
          }}
        />
      </div>
    );
  };

  const DocAbogadosPresentada = () => {
    return (
      <div className="">
        <h4>Documentación para presentar</h4>
        <ListBox
          options={tiposAbogadoPendiente}
          value={selectedExpediente}
          optionLabel="tipoDocumentoNombre"
          style={{ width: '100%' }}
          onChange={(e) => {
            setSelectedExpediente(e.value ? e.value : selectedExpediente);
            if (e.value) {
              documentoClick(e.value);
            }
          }}
        />
      </div>
    );
  };

  const filteredCategory = categorias.filter(
    (x) => x.nombre !== 'Procedimiento de ejecución'
  );

  const filteredProcedimiento = categorias.filter(
    (x) => x.nombre === 'Procedimiento de ejecución'
  );

  const receivePropertyData = (e, item) => {
    setPropertyData(item);
    setEventIndex(e.index);
  };

  const DocCategorias = () => {
    if (todos.length > 0) {
      return (
        <div>
          <Accordion
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
            }}
          >
            {filteredCategory.map((cat, index) => (
              <AccordionTab header={cat.nombre} key={index}>
                <ListBox
                  options={todos.filter(
                    (x) => x.tipoDocumento.categoriaNombre === cat.nombre
                  )}
                  value={selectedExpediente}
                  optionLabel="tipoDocumento.nombre"
                  style={{ width: '100%' }}
                  itemTemplate={statusTemplate}
                  onChange={(e) => {
                    setSelectedExpediente(
                      e.value ? e.value : selectedExpediente
                    );
                    if (e.value) {
                      documentoClick(e.value);
                    }
                  }}
                />
              </AccordionTab>
            ))}

            <AccordionTab header="Procedimiento de ejecución" key={3}>
              <Accordion
                activeIndex={index}
                onTabChange={(e) => setIndex(e.index)}
              >
                {Array.isArray(demandados) &&
                  demandados?.map((cat) => (
                    <AccordionTab
                      header={cat.demandadoNombre}
                      key={cat.demandadoId}
                    >
                      <Accordion
                        activeIndex={eventIndex}
                        onTabChange={(e) =>
                          receivePropertyData(e, cat.bienesDemandado[e.index])
                        }
                      >
                        {cat.bienesDemandado.map((item) => (
                          <AccordionTab header={item.descripcion} key={item.id}>
                            <ListBox
                              options={diligencias || []}
                              value={selectedExpediente}
                              optionLabel="tipoDocumento.nombre"
                              style={{ width: '100%' }}
                              itemTemplate={statusTemplate}
                              onChange={(e) => {
                                setSelectedExpediente(
                                  e.value ? e.value : selectedExpediente
                                );
                                if (e.value) {
                                  documentoClick(e.value);
                                }
                              }}
                            />
                          </AccordionTab>
                        ))}
                      </Accordion>
                    </AccordionTab>
                  ))}
              </Accordion>
              <Accordion>
                {filteredProcedimiento.map((cat, index) => (
                  <AccordionTab header="Otros" key={index}>
                    <ListBox
                      options={todos.filter(
                        (x) => x.tipoDocumento.categoriaNombre === cat.nombre
                      )}
                      value={selectedExpediente}
                      optionLabel="tipoDocumento.nombre"
                      style={{ width: '100%' }}
                      itemTemplate={statusTemplate}
                      onChange={(e) => {
                        setSelectedExpediente(
                          e.value ? e.value : selectedExpediente
                        );
                        if (e.value) {
                          documentoClick(e.value);
                        }
                      }}
                    />
                  </AccordionTab>
                ))}
              </Accordion>
            </AccordionTab>
          </Accordion>
        </div>
      );
    } else {
      return null;
    }
  };

  const DocSinFechaLimite = () => {
    return (
      <div>
        <h4>Repositorio de documentos</h4>
        <ListBox
          options={tiposDocumento}
          value={selectedExpediente}
          optionLabel="tipoDocumento.nombre"
          style={{ width: '100%' }}
          itemTemplate={statusTemplate}
          onChange={(e) => {
            setSelectedExpediente(e.value ? e.value : selectedExpediente);
            if (e.value) {
              documentoClick(e.value);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div id={'listado-documentos'}>
      {tiposAbogadoPendiente.length > 0 ? <DocAbogadosPresentada /> : null}
      {tiposDocumentoLimite.length > 0 ? <DocFechaLimite /> : null}
      {tiposDocumento.length > 0 ? <DocSinFechaLimite /> : null}
      {categorias.length > 0 ? <DocCategorias /> : null}
    </div>
  );
};

export default TipoDocTarjetas;
