import axios from 'axios';
// const apiUrl="https://legacyapi.grupogedesco.com:51443/";
export default class ExpedienteService {
  getExpedientes(data) {
    if (data) {
      return axios
        .get(
          `/api/Expediente?IsEnabled=true${
            data.SortingCriteria
              ? `&SortingCriteria=${data.SortingCriteria}`
              : ''
          }${
            data.MaxResultCount ? `&MaxResultCount=${data.MaxResultCount}` : ''
          }${data.SkipCount ? `&SkipCount=${data.SkipCount}` : ''}${
            data.Keyword ? `&Keyword=${data.Keyword}` : ''
          }`
        )
        .then((res) => res.data);
    }
  }

  getAllExpedientes(data) {
    return axios
      .get('/api/Expediente?MaxResultCount=100')
      .then((res) => res.data);
  }

  getExpedienteByKeyword(data) {
    return axios
      .get(`/api/Expediente?Keyword=${data}&MaxResultCount=100`)
      .then((res) => res.data);
  }

  getExpedienteById(id) {
    return axios.get('/api/Expediente/' + id).then((res) => res.data);
  }

  getExpedientesByKeyword(keyword) {
    return axios
      .get(`/api/Expediente?Keyword=${keyword}&MaxResultCount=100`)
      .then((res) => res.data);
  }

  putExpediente(data) {
    return axios
      .put(
        '/api/Expediente/referencia/' + data.referencia + '/by-id/' + data.id
      )
      .then((res) => res.data);
  }
}
