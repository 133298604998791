import React, { useRef, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import MensajesService from '../service/mensaje.service';
import PropiedadService from '../service/propiedad.service';
import UsuarioService from '../service/usuario.service';
import FormDetalleExpediente from './FormDetalleExpediente';
import TipoDocTarjetas from './TipoDocTarjetas';
import { Launcher } from 'react-chat-window';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from 'primereact/button';
import { setTotalNotificaciones } from 'store/actions/actions';
import DetalleExpedienteService from 'service/detalleExpedienteService';
import { Tooltip } from 'primereact/tooltip';

const propiedadService = new PropiedadService();
const mensajesService = new MensajesService();
const usuarioService = new UsuarioService();
const detalleExpedienteService = new DetalleExpedienteService();

const DataTableDetalleExpediente = ({
  detail,
  idExpediente,
  putExpediente,
  history,
}) => {
  const refChat = useRef();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [messageList, setMessageList] = useState([]);
  const [detalleExpediente, setDetalleExpediente] = useState(detail);
  const [detalleCampos, setDetalleCampos] = useState();
  const [buttons, setButtons] = useState();
  const [referenciaNueva, setReferenciaNueva] = useState();
  const [referenciaCopia, setReferenciaCopia] = useState();
  const [docSeleccionado, setDocSeleccionado] = useState();
  const [tipoDocumento, setTipoDocumento] = useState();

  const [documentoIdLegacy, setDocumentoIdLegacy] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const identityId = sessionStorage?.getItem('userId');

  const handleGetMessages = useCallback(
    async (setMessages = true) => {
      const data = await mensajesService.getMensajes(idExpediente);
      //Fix Write a Reply.... en placeholder del chat
      var divInputChatA = document.getElementsByClassName(
        'sc-user-input--text'
      );
      var divInputChat = divInputChatA[0];
      //divInputChat.setAttribute('placeholder', 'Escribe el mensaje...');

      var mensajes = [];
      for (var i = 0; i < data?.items.length; i++) {
        var objeto = {
          author: data?.items[i].nombreUsuario,
          type: 'text',
          data: {
            text: `${data?.items[i].nombreUsuario} (${moment
              .utc(data?.items[i].creationTime)
              .local()
              .format('DD/MM/YYYY HH:mm')}) - ${data?.items[i].texto}`,
          },
        };
        mensajes.push(objeto);
      }
      if (setMessages) {
        setMessageList(mensajes);
      } else {
        return mensajes;
      }
    },
    [idExpediente]
  );

  useEffect(() => {
    if (sessionStorage?.getItem('userId')) {
      usuarioService
        .getUsuarioByGuid(sessionStorage?.getItem('userId'))
        .then((data) => {
          setUserId(data?.id);
        });
    }
  }, [idExpediente]);

  useEffect(() => {
    if (history.action === 'REPLACE') {
      setDetalleExpediente(detail);
    }
  }, [history, detail]);

  const sendNewMessage = (e) => {};

  const _onMessageWasSent = (message) => {
    if (message.data.text) {
      mensajesService
        .postMessage(message.data.text, userId, idExpediente)
        .then((res) => {
          let mensajes = [];
          if (messageList.length) messageList.map((msg) => mensajes.push(msg));
          let objeto = {
            author: res.data.nombreUsuario,
            type: 'text',
            data: {
              text: `${res.data.nombreUsuario} (${moment(
                res.data.creationTime
              ).format('DD/MM/YYYY HH:mm')}) - ${res.data.texto}`,
            },
          };
          mensajes.push(objeto);
          setMessageList(mensajes);
        });
    }
  };

  const markAsRead = async () => {
    const totalMessages = await handleGetMessages(false);
    if (totalMessages.length !== messageList.length) {
      Swal.fire(
        'Mensajes pendientes',
        'Tiene nuevos mensajes en el expediente, para leerlos vuelva a abrir el chat',
        'info'
      );
      return;
    }
    await mensajesService.markAsRead(idExpediente, identityId);
    const total = await detalleExpedienteService.getTotalNotificaciones();
    dispatch(setTotalNotificaciones(total));
  };

  const clickDocSeleccionado = useCallback((lineaListBox) => {
    if (lineaListBox) {
      if (lineaListBox.tipoDocumento.subidoPorProcurador) {
        propiedadService
          .getPropiedadByTipoId(lineaListBox.tipoDocumento.id)
          .then((data) => {
            setDetalleCampos(data.items);
          });
      }
      setDocSeleccionado(lineaListBox.tipoDocumento.nombre);
      setTipoDocumento({
        docSeleccionado: lineaListBox.tipoDocumento.nombre,
        tipoDocumentoId: lineaListBox.tipoDocumento.id,
        subidoProcurador: lineaListBox.tipoDocumento.subidoPorProcurador,
      });
    }
  }, []);

  const clickDocSeleccionadoLegacy = useCallback((lineaListBox) => {
    if (lineaListBox) {
      setDocSeleccionado(lineaListBox.descripcion);
      setDocumentoIdLegacy(lineaListBox.documentoId);
    }
  }, []);

  /* changePlaceholder = () => {
    console.log('CHILDREN', React.Children.toArray(children));
  }; */

  const showButtons = () => {
    setButtons(!buttons);

    if (buttons) {
      setReferenciaCopia(detalleExpediente.referencia);
      setReferenciaNueva(detalleExpediente.referencia);
    }
  };

  const submitReferencia = () => {
    var nuevoExpedienteReferencia = {
      lastModificationTime: detalleExpediente.lastModificationTime,
      referencia: referenciaNueva,
      id: detalleExpediente.id,
    };

    if (!buttons) {
      setReferenciaNueva(referenciaCopia);
      return;
    }
    putExpediente(nuevoExpedienteReferencia);

    setDetalleExpediente({ ...detalleExpediente, referencia: referenciaNueva });
    setButtons(!buttons);
  };
  const actualizar = () => {
    // setState({actualizar: true});
  };

  const tipoDoc = (
    <>
      <h2 className='cabecera-form-detalle'>{docSeleccionado} </h2>
      <div
        style={{ position: 'absolute', top: '20px', right: '20px' }}
        className='mobile-visible'
      >
        <Button
          icon='pi pi-times'
          className='p-button-rounded close-button'
          onClick={() => {
            setTipoDocumento({ ...tipoDocumento, tipoDocumentoId: null });
          }}
        />
      </div>

      <FormDetalleExpediente
        history={history}
        expedienteId={detalleExpediente?.id}
        tipoDocumentoId={tipoDocumento?.tipoDocumentoId}
        subidoProcurador={
          tipoDocumento?.subidoProcurador
            ? tipoDocumento.subidoProcurador
            : null
        }
        titulo={docSeleccionado}
        detalleCampos={detalleCampos}
        actualizar={actualizar()}
      />
    </>
  );

  const buttonsStack = (
    <>
      <Input
        autoFocus
        onChange={(e) => {
          setReferenciaNueva(e.value);
        }}
        value={referenciaNueva}
      />

      <Button
        icon='pi pi-check'
        className='p-button-rounded  p-button-text'
        onClick={submitReferencia}
      />
      <Button
        icon='pi pi-times'
        className='p-button-rounded  p-button-text'
        onClick={showButtons}
      />
    </>
  );

  const subTitulo = (
    <>
      <span className='referencia-title'>
        {referenciaNueva ? referenciaNueva : detalleExpediente?.referencia}
      </span>

      <Button
        icon='pi pi-pencil'
        className='p-button-rounded  p-button-text'
        onClick={showButtons}
      />
    </>
  );
  return (
    <div className='detalle-templating'>
      <h2 className='detalle-expediente-title'>
        {detalleExpediente?.procedimiento} {detalleExpediente?.juzgado} -{' '}
        {detalleExpediente?.demandados[0]}
        {detalleExpediente?.demandados.length > 1 && (
          <div className='info__tooltip'>
            {'...'}
            <Tooltip target='.custom-target-icon' />
            <i
              className='custom-target-icon pi pi-plus-circle'
              data-pr-tooltip={detalleExpediente?.demandados?.join('\n')}
              style={{ fontSize: '1.5rem', cursor: 'pointer' }}
            ></i>
          </div>
        )}
      </h2>
      <div className='referencia-editable'>
        <span className='label-referencia'>Referencia: </span>
        {buttons ? buttonsStack : subTitulo}
        <span style={{ float: 'right' }}>
          {detalleExpediente?.tipoExpedienteNombre} -
          {detalleExpediente?.abogado}
        </span>
      </div>
      <div id='detalle-expediente'>
        <div className='row'>
          <div className='col-md-4'>
            <TipoDocTarjetas
              expedienteId={detalleExpediente?.id}
              clickDocSeleccionado={clickDocSeleccionado}
              clickDocSeleccionadoLegacy={clickDocSeleccionadoLegacy}
            />
          </div>
          <div className='col-md-8 container-ficha-expediente'>
            {tipoDocumento?.tipoDocumentoId ? tipoDoc : null}
          </div>
        </div>
      </div>

      <Launcher
        agentProfile={{
          teamName: `Mensajes Expediente - Demandados: ${detalleExpediente?.demandados.join(
            ', '
          )} - Procedimiento: ${detalleExpediente?.procedimiento}`,
          imageUrl: '/procuradores/static/media/logo-no-bg.7718b3e3.svg',
        }}
        ref={refChat}
        onMessageWasSent={_onMessageWasSent.bind(this)}
        messageList={messageList}
        showEmoji={false}
        handleClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) {
            markAsRead();
          } else {
            handleGetMessages();
          }
        }}
        isOpen={isOpen}
      />
    </div>
  );
};

export default DataTableDetalleExpediente;
