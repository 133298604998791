import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
export default class DocumentosService {
  getFileB64(documentoId) {
    return axios
      .get('/api/Documento/base64-pdf/by-id/' + documentoId)
      .then((res) => res.data);
  }

  getFileB64Legacy(documentoId) {
    return axios
      .get('/api/DocumentoLegacy/base64-pdf/by-id/' + documentoId)
      .then((res) => res.data);
  }
  getZipB64Documento(expedienteId, tipoDocumentoId, pendiente = true) {
    return axios
      .get(
        `/api/DocumentoAbogado/zip/by-expediente-id/${expedienteId}/by-tipo-documento-id/${tipoDocumentoId}/by-pendiente/${pendiente}`
      )
      .then((res) => res.data);
  }

  getDocumentoAbogadoIndice(expedienteId, tipoDocumentoId) {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/DocumentoAbogado/indice/by-expediente-id/${expedienteId}/by-tipo-documento-id/${tipoDocumentoId}`
      )
      .then((res) => res.data);
  }

  getDocumentosByFecha(tipoRango, rango) {
    var desde = moment().format('YYYY-MM-DD');
    var hasta = moment().add(1, 'd').format('YYYY-MM-DD');

    if (tipoRango === 2) {
      //Semana

      var desde = moment().subtract(7, 'd').format('YYYY-MM-DD');
      var hasta = moment().format('YYYY-MM-DD');
    } else if (tipoRango === 3) {
      //Rango

      var desde = moment(rango ? rango[0] : '').format('YYYY-MM-DD');
      var hasta = moment(rango ? rango[1] : '').format('YYYY-MM-DD');
    }

    return axios
      .get(
        `/api/Documento/between-dates?desde=${desde}&hasta=${hasta}&MaxResultCount=100`
      )
      .then((res) => res.data);
  }

  deleteDocumentoById(idDoc) {
    return axios.delete(`api/Documento/${idDoc}`).then((res) => res.data);
  }

  putDocumentoAbogadoLeidoById(idDoc) {
    return axios
      .put(`/api/DocumentoAbogado/leido/by-id/${idDoc}`)
      .then((res) => res.data);
  }
  putDocumentoAbogadoLeidoByIdExpediente(idExpediente, idDocumento) {
    return axios
      .put(
        `/api/DocumentoAbogado/leido/by-expediente-id/${idExpediente}/by-tipo-documento-id/${idDocumento}`
      )
      .then((res) => res.data);
  }

  getDocumentoAbogadoExistsNoLeidosByExpediente(idExpediente) {
    return axios
      .get(
        `/api/DocumentoAbogado/exists-no-leidos/by-expediente-id/${idExpediente}`
      )
      .then((res) => res.data);
  }

  getDocumentoJustificante(idDocumento) {
    return axios
      .get(`/api/Documento/base64-pdf-summary/by-id/${idDocumento}`)
      .then((res) => res.data);
  }
}
